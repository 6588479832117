import colors from "assets/theme/base/colors";

const dataGrid = {
  styleOverrides: {
    root: {
      border: "none",

      "& .MuiDataGrid-colCell": {
        textTransform: "capitalize",
      },

      "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
        "& .MuiDataGrid-cellContent": {
          margin: 0,
          fontSize: "0.75rem",
          lineHeight: 1.25,
          letterSpacing: "0.03333em",
          opacity: 1,
          textTransform: "none",
          verticalAlign: "unset",
          textDecoration: "none",
          color: "rgb(103, 116, 142)",
          fontWeight: 500,
        },
        "& .MuiDataGrid-cellValue": {
          display: "flex",
          alignItems: "center",
        },
        "& .MuiSelect-icon": {
          color: "rgba(0, 0, 0, 0.54)",
        },
        "& .dataGrid-options-container:empty::before": {
          color: "gray",
        },
      },
      "& .MuiDataGrid-row": {
        "&:nth-child(even)": {
          backgroundColor: colors.grey[100],
        },
        "&.Mui-selected": {
          backgroundColor: "#E7F7FD",
          "&:hover": {
            backgroundColor: colors.info.lightest,
          },
        },
      },
      "& .MuiDataGrid-iconSeparator": {
        display: "none",
      },

      "& .MuiDataGrid-footerContainer": {
        "& .MuiTablePagination-root": {
          "& .MuiToolbar-root": {
            "& .MuiInputBase-root": {
              width: 100,
            },
          },
        },
      },

      "& .MuiInputBase-root-MuiTablePagination-select": {
        width: "10%",
      },
    },
    cell: {
      padding: "8px",
    },
    columnHeaders: {
      display: "block",
      color: "#31456A",
      fontSize: "0.65rem",
      fontWeight: 700,
      textTransform: "uppercase",
      opacity: 0.7,
      userSelect: "none",
      margin: 0,
    },
  },
};

export default dataGrid;
