import flatten from "lodash/flatten";
// MUI
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
// Helpers
import lazy from "helpers/LazyImport";
// FEATURES
import features from "config/features";
// layout
import BodyWrapper from "../../layout/bodyWrapper";
import Container from "../../layout/simpleContainer";
// component
const AllTests = lazy(() => import("../../views/Analysis/AllTests"));
const CumulativeResults = lazy(() =>
  import("../../views/Analysis/CumulativeResults")
);
const TestDetails = lazy(() =>
  import("../../views/Analysis/AllTests/TestResults")
);
const StudentOverallResult = lazy(() =>
  import("../../views/Analysis/StudentOverallResult")
);
const StudentAnswers = lazy(() =>
  import("../../views/Analysis/StudentIndividualResult")
);
const ToppersReport = lazy(() => import("../../views/Analysis/ToppersReport"));
const ToppersReportView = lazy(() =>
  import("../../views/Analysis/ToppersReport/ToppersReportView")
);
const ToppersErrorList = lazy(() =>
  import("../../views/Analysis/TopperErrorList")
);
const ToppersErrCompactView = lazy(() =>
  import("../../views/Analysis/TopperErrorList/ErrorListCompactView")
);
const ToppersErrorsView = lazy(() =>
  import("../../views/Analysis/TopperErrorList/ErrorListView")
);
const CampusErrorReport = lazy(() =>
  import("../../views/Analysis/CampusErrorReport")
);
const ViewCampusErrorReport = lazy(() =>
  import("../../views/Analysis/CampusErrorReport/ReportView")
);
const LeastAttemptedQuestion = lazy(() =>
  import("../../views/Analysis/LeastAttemptedQuestion")
);
const ViewLeastAttemptedQuestion = lazy(() =>
  import("../../views/Analysis/LeastAttemptedQuestion/LeastAttemptView")
);
const TestToTestComparision = lazy(() =>
  import("../../views/Analysis/TestToTestComparison")
);
const ViewTestToTestComparison = lazy(() =>
  import("../../views/Analysis/TestToTestComparison/CompareTestView")
);
const CWUcount = lazy(() => import("../../views/Analysis/SubjectWiseCWUCount"));
const ViewCWUcount = lazy(() =>
  import("../../views/Analysis/SubjectWiseCWUCount/CWUCountView")
);
const BoardTestAnalysis = lazy(() =>
  import("../../views/Analysis/BoardExamAnalaysis")
);
// const SingleTestAnalysis = lazy(() =>
//   import("../../views/Analysis/SingleTestAnalysis")
// );
const ErrorAnalysis = lazy(() =>
  import("../../views/Analysis/SingleTestAnalysis/ErrorAnalysis")
);
const Card = lazy(() => import("../../components/MobileSubModules"));

export default {
  component: <BodyWrapper />,
  title: "Analysis",
  basePath: "app",
  path: "analysis",
  index: <Card path="analysis" />,
  authorizedRoles: [],
  features: flatten(Object.values(features.analysis)),
  icon: <AnalyticsRoundedIcon />,
  children: [
    // Rank List alias all-test

    {
      component: <Container />,
      title: "Rank List",
      basePath: "analysis",
      path: "all-tests",
      index: <AllTests />,
      authorizedRoles: [],
      features: features.analysis.allTests,
      children: [
        {
          component: <TestDetails />,
          title: "Test Results",
          basePath: "all-tests",
          path: "test-results",
          features: ["download_tests_result"],
          authorizedRoles: [],
        },
      ],
    },
    {
      component: <CumulativeResults />,
      title: "Cumulative Rlst & Est.Avg.",
      basePath: "analysis",
      path: "cumulativeresults",
      authorizedRoles: [],
      features: features.analysis.cumulativeresults,
    },
    {
      component: <ErrorAnalysis />,
      title: "Error Analysis",
      basePath: "analysis",
      path: "error-Analysis",
      authorizedRoles: [],
      noUiLink: true,
      features: features.analysis.cumulativeresults,
    },
    {
      component: <StudentOverallResult />,
      title: "Progress Report",
      basePath: "analysis",
      path: "progress-report",
      features: features.analysis.progressReport,
      authorizedRoles: [],
    },
    // progress report
    // {
    //   component: <AdminPortalComponent />,
    //   title: "Progress Report",
    //   basePath: "analysis",
    //   adminPath: true,
    //   path: "progress-report",
    //   icon: <AutorenewRoundedIcon />,
    //   authorizedRoles: [],
    // },
    // student answers
    {
      component: <StudentAnswers />,
      title: "Student Answers",
      basePath: "analysis",
      path: "student-answer",
      features: features.analysis.studentAnswers,
      authorizedRoles: [],
    },
    // {
    //   component: <SingleTestAnalysis />,
    //   title: " Single Test Analysis",
    //   basePath: "analysis",
    //   path: "test-analysis",
    //   features: features.analysis.boardExamAnalysis,
    //   authorizedRoles: [],
    // },

    {
      component: <BoardTestAnalysis />,
      title: "Board Exam Analysis",
      basePath: "analysis",
      path: "board-test-analysis",
      features: features.analysis.boardExamAnalysis,
      authorizedRoles: [],
    },

    // // cumilative results
    // {
    //   component: <AdminPortalComponent />,
    //   title: "Cumulative Rlst & Est.Avg.",
    //   basePath: "analysis",
    //   adminPath: true,
    //   path: "cumulativeresults",
    //   icon: <FunctionsRoundedIcon />,
    //   authorizedRoles: [],
    // },
    // // toppers report
    {
      component: <Container />,
      title: "Topper's Report",
      basePath: "analysis",
      path: "toppers-report",
      features: features.analysis.toppersReport,
      authorizedRoles: [],
      index: <ToppersReport />,
      children: [
        {
          component: <ToppersReportView />,
          title: "Toppers Report",
          basePath: "toppers-report",
          path: "view",
          features: ["view_report", "download_report", "view_toppers_report"],
          authorizedRoles: [],
        },
      ],
    },
    // // toppers error
    {
      component: <Container />,
      title: "Topper's Error List",
      basePath: "analysis",
      path: "toppers-error",
      features: features.analysis.toppers_errors_list,
      index: <ToppersErrorList />,
      authorizedRoles: [],
      children: [
        {
          component: <ToppersErrCompactView />,
          title: "Toppers Error's compact view",
          basePath: "toppers-error",
          path: "compact-view",
          features: [
            "download_errors_list",
            "view_in_compact",
            "view_toppers_Errors_list",
          ],
          authorizedRoles: [],
        },
        {
          component: <ToppersErrorsView />,
          title: "Toppers Error's",
          basePath: "toppers-error",
          path: "view",
          features: [
            "download_errors_list",
            "view_Errors_list",
            "view_toppers_Errors_list",
          ],
          authorizedRoles: [],
        },
      ],
    },
    // // campus error report
    {
      component: <Container />,
      index: <CampusErrorReport />,
      title: "Campus Error Report",
      features: features.analysis.campus_errors_list,
      basePath: "analysis",
      path: "campus-error-report",
      authorizedRoles: [],
      children: [
        {
          component: <ViewCampusErrorReport />,
          title: "View",
          basePath: "campus-error-report",
          path: "view",
          features: features.analysis.campus_errors_list,
        },
      ],
    },
    // // leat attempted questions
    {
      component: <Container />,
      title: "Least Attempted Questions",
      basePath: "analysis",
      path: "least-attempted-questions",
      authorizedRoles: [],
      features: features.analysis.least_attempted_questions,
      index: <LeastAttemptedQuestion />,
      children: [
        {
          component: <ViewLeastAttemptedQuestion />,
          title: "View",
          basePath: "least-attempted-questions",
          path: "view",
          features: features.analysis.least_attempted_questions,
        },
      ],
    },
    // // test comparision
    {
      component: <Container />,
      title: "Test to Test comparison",
      basePath: "analysis",
      path: "test-comparison",
      features: features.analysis.view_test_test_comparison,
      index: <TestToTestComparision />,
      authorizedRoles: [],
      children: [
        {
          component: <ViewTestToTestComparison />,
          title: "View",
          basePath: "test-comparison",
          path: "view",
          features: features.analysis.view_test_test_comparison,
        },
      ],
    },
    // // leat attempted questions
    {
      component: <Container />,
      title: "CWU count analysis",
      basePath: "analysis",
      path: "cwu-count",
      index: <CWUcount />,
      authorizedRoles: [],
      features: features.analysis.cwu_count_analysis,
      children: [
        {
          component: <ViewCWUcount />,
          title: "View",
          basePath: "cwu-count",
          path: "view",
          features: features.analysis.cwu_count_analysis,
          authorizedRoles: [],
        },
      ],
    },
  ],
};
