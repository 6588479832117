import { useState } from "react";
// @mui material components
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";
import KeyboardArrowUpRoundedIcon from "@mui/icons-material/KeyboardArrowUpRounded";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import Menu from "@mui/material/Menu";
// components
import ZTypography from "myComponents/SoftTypography";
import SoftButton from "myComponents/SoftButton";
import SoftBox from "myComponents/SoftBox";
// colors
// import colors from "assets/theme/base/colors";
// menuitem
import menuItem from "../AccountMenu/styles";

const AcademicYearMenu = ({ academicYears, handleAyClick, ayItem }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <SoftBox display="flex">
      <SoftButton size="small" variant="text" onClick={handleClick}>
        {ayItem.name}
        &nbsp;&nbsp;
        <Icon sx={{ fontSize: 16 }}>
          {open ? (
            <KeyboardArrowUpRoundedIcon />
          ) : (
            <KeyboardArrowDownRoundedIcon />
          )}
        </Icon>
      </SoftButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        // color="dark"
      >
        {academicYears?.length > 0 &&
          academicYears.map((ay, index) => (
            <MenuItem
              key={`ay-${index}`}
              onClick={() => {
                handleAyClick(academicYears[index]);
                handleClose();
              }}
              sx={(theme) => menuItem(theme)}
            >
              <ZTypography variant="button" fontWeight="regular" sx={{ ml: 1 }}>
                {ay.name}
              </ZTypography>
            </MenuItem>
          ))}
      </Menu>
    </SoftBox>
  );
};

export default AcademicYearMenu;
