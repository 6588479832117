import { createSlice } from "@reduxjs/toolkit";
// list
import desList from "../../config/roles";

export const L = ({ num, sup }) => (
  <span>
    {num}
    <sup>{sup}</sup>
  </span>
);

export const initialState = {
  classes: {
    Nursery: { label: "Nursery", label1: "Nursery", value: "Nursery" },
    LKG: { label: "LKG", label1: "LKG", value: "LKG" },
    UKG: { label: "UKG", label1: "UKG", value: "UKG" },
    1: {
      label1: <L num="I" sup="st" />,
      value: "1",
      label: "I",
    },
    2: {
      label1: <L num="II" sup="nd" />,
      value: "2",
      label: "II",
    },
    3: {
      label1: <L num="III" sup="rd" />,
      value: "3",
      label: "III",
    },
    4: {
      label1: <L num="IV" sup="th" />,
      value: "4",
      label: "IV",
    },
    5: {
      label1: <L num="V" sup="th" />,
      value: "5",
      label: "V",
    },
    6: {
      label1: <L num="VI" sup="th" />,
      value: "6",
      label: "VI",
    },
    7: {
      label1: <L num="VII" sup="th" />,
      value: "7",
      label: "VII",
    },
    8: {
      label1: <L num="VIII" sup="th" />,
      value: "8",
      label: "VIII",
    },
    9: {
      label1: <L num="IX" sup="th" />,
      value: "9",
      label: "IX",
    },
    10: {
      label1: <L num="X" sup="th" />,
      value: "10",
      label: "X",
    },
    11: {
      label1: <L num="XI" sup="th" />,
      value: "11",
      label: "XI",
    },
    12: {
      label1: <L num="XII" sup="th" />,
      value: "12",
      label: "XII",
    },
    13: {
      label1: <L num="Year-1" sup="" />,
      value: "13",
      label: "Year-1",
    },
    14: {
      label1: <L num="Year-2" sup="" />,
      value: "14",
      label: "Year-2",
    },
    15: {
      label1: <L num="Year-3" sup="" />,
      value: "15",
      label: "Year-3",
    },
    16: {
      label1: <L num="Year-4" sup="" />,
      value: "16",
      label: "Year-4",
    },
    17: {
      label1: <L num="Year-5" sup="" />,
      value: "17",
      label: "Year-5",
    },
    18: {
      label1: <L num="MPG-1" sup="" />,
      value: "18",
      label: "PG-1",
    },
    19: {
      label1: <L num="MPG-2" sup="" />,
      value: "19",
      label: "PG-2",
    },
    20: {
      label1: <L num="MPG-3" sup="" />,
      value: "20",
      label: "PG-3",
    },
    21: {
      label1: <L num="INS" sup="" />,
      value: "21",
      label: "INS",
    },
  },
  courses: {
    JM: { label: "JEE Main", value: "JM", group: "MPC" },
    JA: { label: "JEE Advanced", value: "JA", group: "MPC" },
    NE: { label: "NEET", value: "NE", group: "BiPC" },
    BI: { label: "BITSAT", value: "BI", group: "MPC" },
    VI: { label: "VITEEE", value: "VI", group: "MPC" },
    EM: { label: "EAMCET-MPC", value: "EM", group: "MPC" },
    EB: { label: "EAMCET-BiPC", value: "EB", group: "BiPC" },
    MC: { label: "MEC-Course", value: "MC", group: "MEC" },
    CC: { label: "CEC-Course", value: "CC", group: "CEC" },
    HC: { label: "HEC-Course", value: "HC", group: "HEC" },
  },
  groups: {
    MPC: { label: "MPC", value: "MPC" },
    BiPC: { label: "BiPC", value: "BiPC" },
    CEC: { label: "CEC", value: "CEC" },
    HEC: { label: "HEC", value: "HEC" },
    MEC: { label: "MEC", value: "MEC" },
    ECH: { label: "ECH", value: "ECH" },
    SSC: { label: "SSC", value: "SSC" },
    CBSE: { label: "CBSE", value: "CBSE" },
    ICSE: { label: "ICSE", value: "ICSE" },
  },
  boardSubjects: {
    "LANGUAGE-I": {
      name: "LANGUAGE-I",
      value: "LANGUAGE-I",
    },
    "LANGUAGE-II": {
      name: "LANGUAGE-II",
      value: "LANGUAGE-II",
    },
    "MATHS-A": {
      name: "MATHS-A",
      value: "MATHS-A",
    },
    "MATHS-B": {
      name: "MATHS-B",
      value: "MATHS-B",
    },
    MATHEMATICS: {
      name: "MATHEMATICS",
      value: "MATHEMATICS",
    },
    PHYSICS: {
      name: "PHYSICS",
      value: "PHYSICS",
    },
    CHEMISTRY: {
      name: "CHEMISTRY",
      value: "CHEMISTRY",
    },
    BOTANY: {
      name: "BOTANY",
      value: "BOTANY",
    },
    ZOOLOGY: {
      name: "ZOOLOGY",
      value: "ZOOLOGY",
    },
    BIOLOGY: {
      name: "BIOLOGY",
      value: "BIOLOGY",
    },
    ENGLISH: {
      name: "ENGLISH",
      value: "ENGLISH",
    },
    SANSKRIT: {
      name: "SANSKRIT",
      value: "SANSKRIT",
    },
    TELUGU: {
      name: "TELUGU",
      value: "TELUGU",
    },
    HINDI: {
      name: "HINDI",
      value: "HINDI",
    },
    SCIENCE: {
      name: "SCIENCE",
      value: "SCIENCE",
    },
    REASONING: {
      name: "REASONING",
      value: "REASONING",
    },
    CIVICS: {
      name: "CIVICS",
      value: "CIVICS",
    },
    COMMERCE: {
      name: "COMMERCE",
      value: "COMMERCE",
    },
    ACCOUNTS: { name: "ACCOUNTS", value: "ACCOUNTS" },
    ECONOMICS: {
      name: "ECONOMICS",
      value: "ECONOMICS",
    },
    BUSINESS_LAW: {
      name: "BUSINESS_LAW",
      value: "BUSINESS_LAW",
    },
    LEGAL_REASONING: {
      name: "LEGAL_REASONING",
      value: "LEGAL_REASONING",
    },
    GK: {
      name: "GK",
      value: "GK",
    },
    QA: {
      name: "QA",
      value: "QA",
    },
    PHYSICAL_SCIENCE: {
      name: "PHYSICAL_SCIENCE",
      value: "PHYSICAL_SCIENCE",
    },
    SOCIAL_SCIENCE: {
      name: "SOCIAL_SCIENCE",
      value: "SOCIAL_SCIENCE",
    },
  },
  studentStatus: {
    Active: { label: "Active", value: "Active" },
    InActive: { label: "In Active", value: "In Active" },
  },
  joiningYear: {
    All: { label: "All", value: "all" },
    New: { label: "New", value: "new" },
    Existing: { label: "Existing", value: "existing" },
  },
  accountType: {
    Students: { label: "Students", value: null },
    Parents: { label: "Parents", value: "parent" },
  },
  // Admissions
  admissionTypes: {
    dayScholar: { label: "Dayscholar", value: "dayScholar" },
    residential: { label: "Residential", value: "residential" },
  },
  admissionToAcademicYear: {
    current: { label: "Current Academic Year", value: "current" },
    next: { label: "Next Academic Year", value: "next" },
  },
  status: {
    Admitted: { label: "Admitted", value: "Admitted" },
    "Not Admitted": { label: "Not Admitted", value: "Not Admitted" },
    "Application Taken but Not Joined": {
      label: "Application Taken but Not Joined",
      value: "Application Taken but Not Joined",
    },
  },
  source: {
    "Campus walk-in": { label: "Campus walk-in", value: "Campus walk-in" },
    Website: { label: "Website", value: "Website" },
    Others: {
      label: "Others",
      value: "Others",
    },
  },
  stage: {
    Open: { label: "Open", value: "Open" },
    Interested: { label: "Interested", value: "Interested" },
    "Not Interested": { label: "Not Interested", value: "Not Interested" },
    "Not Responded": { label: "Not Responded", value: "Not Responded" },
    "Joined another college": {
      label: "Joined another college",
      value: "Joined another college",
    },
  },
  response: {
    "Ready to join": { label: "Ready to join", value: "Ready to join" },
    Interested: {
      label: "Interested but Holding",
      value: "Interested but Holding",
    },
    "About fee Concession": {
      label: "About fee Concession",
      value: "About fee Concession",
    },
    "Joined another college": {
      label: "Joined another college",
      value: "Joined another college",
    },
    "Not decided yet": {
      label: "Not decided yet",
      value: "Not decided yet",
    },
    "Call Back": {
      label: "Call Back",
      value: "Call Back",
    },
    "Not answering": {
      label: "Not answering",
      value: "Not answering",
    },
    "Technical issues": {
      label: "Technical issues",
      value: "Technical issues",
    },
    "Wrong number": {
      label: "Wrong number",
      value: "Wrong number",
    },
    "Hyderabad not Interested": {
      label: "Hyderabad not Interested",
      value: "Hyderabad not Interested",
    },
    "Interest for Home visit": {
      label: "Interest for Home visit",
      value: "Interest for Home visit",
    },
  },
  remarks: {
    "Fee issues": { label: "Fee issues", value: "Fee issues" },
  },
  // finance
  statusofPaymentFollowup: {
    PhoneNotReachableC: {
      label: "Phone not reachable",
      value: "Phone not reachable",
    },
    NotAnswered: { label: "Not Answered", value: "Not Answered" },
    Connected: { label: "Connected", value: "Connected" },
  },
  contactWithPersonOptions: {
    Father: { label: "Father", value: "father" },
    Mother: { label: "Mother", value: "mother" },
    Student: { label: "Student", value: "student" },
    Other: { label: "Other", value: "other" },
  },
  concessionTypeList: {
    GeneralConcession: {
      label: "General Concession",
      value: "General Concession",
    },
    StudentRelatedConcession: {
      label: "Student Related Concession",
      value: "Student Related Concession",
    },
    StaffRelatedConcession: {
      label: "Staff Related Concession",
      value: "Staff Related Concession",
    },
  },
  palnApplicableTo: {
    existing_students: {
      label: "Existing Students",
      value: "existing_students",
    },
    new_admission_students: {
      label: "New Admission students",
      value: "new_admission_students",
    },
  },
  reservationType: {
    resdential: { label: "Resdential", value: "resdential" },
    dayscholar: { label: "Dayscholar", value: "dayscholar" },
    semi_resdential: { label: "Semi Residential", value: "semi_resdential" },
  },
  feeTypeList: {
    admission_fee: { label: "Admission Fee", value: "admission_fee" },
    belt_fee: { label: "Belt Fee", value: "belt_fee" },
    books_fee: { label: "Books Fee", value: "books_fee" },
    caution_deposit_fee: {
      label: "Caution Deposit Fee",
      value: "caution_deposit_fee",
    },
    dhobi_fee: { label: "Dhobi Fee", value: "dhobi_fee" },
    exam_fee: { label: "Exam Fee", value: "exam_fee" },
    hostel_fee: { label: "Hostel Fee", value: "hostel_fee" },
    hot_water_fee: { label: "Hot water fee", value: "hot_water_fee" },
    id_card_fee: { label: "Id card Fee", value: "id_card_fee" },
    lab_fee: { label: "Lab Fee", value: "lab_fee" },
    lunch_fee: { label: "Lunch Fee", value: "lunch_fee" },
    online_exam_fee: { label: "Online Exam Fee", value: "online_exam_fee" },
    registration_fee: { label: "Registration Fee", value: "registration_fee" },
    store_deposit: { label: "Store Deposit", value: "store_deposit" },
    school_fee: { label: "School Fee", value: "school_fee" },
    other_fee: { label: "Other Fee", value: "other_fee" },
    school_essentials: {
      label: "School Essentials",
      value: "school_essentials",
    },
    // school_essentials_old: {
    //   label: "School Essentials Old",
    //   value: "school_essentials_old",
    // },
    sports_and_activities: {
      label: "Sports and Activities",
      value: "sports_and_activities",
    },
    tab_fee: { label: "Tab Fee", value: "tab_fee" },
    tution_fee: { label: "Tution Fee", value: "tution_fee" },
    // transport_fee: {
    //   label: "Transport Route 0 Fee",
    //   value: "transport_fee",
    //   receipt_name: "Transport Fee",
    // },
    // transport_1_fee: {
    //   label: "Transport Route 1 Fee",
    //   value: "transport_1_fee",
    //   receipt_name: "Transport Fee",
    // },
    // transport_2_fee: {
    //   label: "Transport Route 2 Fee",
    //   value: "transport_2_fee",
    //   receipt_name: "Transport Fee",
    // },
    // transport_3_fee: {
    //   label: "Transport Route 3 Fee",
    //   value: "transport_3_fee",
    //   receipt_name: "Transport Fee",
    // },
    // transport_4_fee: {
    //   label: "Transport Route 4 Fee",
    //   value: "transport_4_fee",
    //   receipt_name: "Transport Fee",
    // },
    // transport_5_fee: {
    //   label: "Transport Route 5 Fee",
    //   value: "transport_5_fee",
    //   receipt_name: "Transport Fee",
    // },
    uniform_fee: { label: "Uniform Fee", value: "uniform_fee" },
  },
  paymentMode: {
    cash: { label: "cash", value: "cash" },
    cheque: { label: "cheque", value: "cheque" },
  },
  hallTicketTypes: {
    I_BOARD: { label: "Class 11 BOARD", value: "I_BOARD" },
    II_BOARD: { label: "Class 12 BOARD", value: "II_BOARD" },
    JEE_MAINS: { label: "JEE_MAINS", value: "JEE_MAINS" },
    JEE_ADVANCED: { label: "JEE_ADVANCED", value: "JEE_ADVANCED" },
    AP_EAMCET: { label: "AP_EAMCET", value: "AP_EAMCET" },
    TS_EAMCET: { label: "TS_EAMCET", value: "TS_EAMCET" },
  },
  cancelReciptLogics: {
    AsPerRecieptDate: {
      label: "As Per Reciept Date",
      value: "As Per Reciept Date",
    },
    AsPerCancelDate: {
      label: "As Per Cancel Date",
      value: "As Per Cancel Date",
    },
  },
  concessionNames: {
    "Final due fee": { label: "Final due fee", value: "Final due fee" },
    "Internal exam fee": {
      label: "Internal exam fee",
      value: "Internal exam fee",
    },
    "Annual exam fee": { label: "Annual exam fee", value: "Annual exam fee" },
    "Bus fee": { label: "Bus fee", value: "Bus fee" },
    "Hostel fee": { label: "Hostel fee", value: "Hostel fee" },
    "Eligibility certificate fee": {
      label: "Eligibility certificate fee",
      value: "Eligibility certificate fee",
    },
    "Admission fee": { label: "Admission fee", value: "Admission fee" },
    "Manual fee": { label: "Manual fee", value: "Manual fee" },
    Scholarship: { label: "Scholarship", value: "Scholarship" },
    "Tc admission fee": {
      label: "Tc admission fee",
      value: "Tc admission fee",
    },
    "Tour amount": { label: "Tour amount", value: "Tour amount" },
    "Tuition fee": { label: "Tuition fee", value: "Tuition fee" },
    "Tuition fee jee & neet": {
      label: "Tuition fee jee & neet",
      value: "Tuition fee jee & neet",
    },
  },
  ApprovedFromList: {
    "No one Approved ": {
      label: "No one Approved ",
      value: "No one Approved ",
    },
    Chairman: { label: "Chairman", value: "Chairman" },
    director: { label: "director", value: "director" },
    Principal: { label: "Principal", value: "Principal" },
    Staff: { label: "Staff", value: "Staff" },
  },
  biometricDeviceType: {
    students: { label: "students", value: "students" },
    employees: { label: "employees", value: "employees" },
  },
  // hostel
  hostelType: {
    Boys: { label: "Boys", value: "Boys" },
    Girls: { label: "Girls", value: "Girls" },
  },
  concessionType: {
    percentage: { label: "percentage", value: "percentage" },
    amount: { label: "amount", value: "amount" },
  },
  accessLevels: {
    organization: { label: "organization", value: "organization" },
    designationsList: { label: "designationsList", value: "designationsList" },
  },

  designationsList: desList,
  daysOfWeek: {
    MON: { label: "MONDAY", value: "MON" },
    TUE: { label: "TUESDAY", value: "TUE" },
    WED: { label: "WEDNESDAY", value: "WED" },
    THU: { label: "THURSDAY", value: "THU" },
    FRI: { label: "FRIDAY", value: "FRI" },
    SAT: { label: "SATURDAY", value: "SAT" },
    SUN: { label: "SUNDAY", value: "SUN" },
  },
};

const staticSlice = createSlice({
  name: "STATIC",
  initialState,
});

export default staticSlice.reducer;
