import axios from "../baseAxios";

export async function getQuestionFromQB(body) {
  try {
    const data = await axios.post("/test-creation/questionbank", body);
    return data;
  } catch (error) {
    return error.message;
  }
}

export async function getClassDetails(query = "") {
  try {
    const data = await axios.get(
      `/test-creation/classdetails?standard=${query}`
    );
    return data;
  } catch (error) {
    return error;
  }
}

export async function getSingleTestPaperAPI(testId) {
  try {
    const data = await axios.post(`/test-creation/testpaper/${testId}`);
    return data;
  } catch (error) {
    return error;
  }
}

export async function saveTestPaper(body) {
  try {
    const data = await axios.post("/test-creation/createtestpaper", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function getMarkingSchema(body) {
  try {
    const data = await axios.post("/test-creation/marking-schema", body);
    return data;
  } catch (error) {
    return error;
  }
}

export async function uploadPdfForCustomTest(body) {
  try {
    const data = await axios.post(
      "/test-creation/uploadPdfForCustomTest",
      body
    );
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function createSingleQuestions(body) {
  try {
    const data = await axios.post("/test-creation/createSingleQuestions", body);
    return data.data;
  } catch (error) {
    return error;
  }
}

export async function updateQuestion(params) {
  try {
    const url = `/questionbank/${params.id}`;
    const data = await axios.put(url, params);
    return data.data;
  } catch (error) {
    return error;
  }
}
