import { useSelector } from "react-redux";
import store from "store";
// import filtersSelectors from "../../redux/filters/filters.selectors";
import { selectZonesList } from "../../redux/organization/zones/zones.selectors";
import { selectAcademicYears } from "../../redux/organization/academicYear/academicYear.selectors";
import roles from "../../config/roles";
// names
import {
  FIELD_NAMES,
  FIELD_TYPES,
  SELECT_TYPES,
} from "../../config/globalConsts";
// filter utils
import {
  filterBranchesByZone,
  filterClassesByBranch,
  filterBatchesByBranchesAndGrades,
  filterSectionsByBranchClassAndBatch,
  filterHostelByBranch,
  filterCounsellorsByBranch,
  filterBranchesIfContainsBatch,
  filterTestsByBatch,
  // filterSectionsByBatch,
  filterEmployeesByDesignation,
} from "./filter.utils";
import { generateDropDownList } from "../general.utils/array.utils";
import { getObjectKeys, getObjectValues } from "../general.utils/object.utils";

const makeArr = (val) => (val instanceof Array ? val : [val]);

export const academicYearFieldDetails = ({
  name = "academicYear",
  selectType = SELECT_TYPES.SINGLE,
}) => {
  const academicYears = useSelector(selectAcademicYears);
  return {
    name,
    label2: "Academic Year",
    key: "name",
    type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
    getList: () => academicYears,
  };
};

export const zoneFieldDetails = ({
  selectType = SELECT_TYPES.SINGLE,
  name,
}) => {
  const zonesList = useSelector(selectZonesList);
  return {
    name,
    label2: "Zone",
    key: "zoneName",
    type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
    getList: () => zonesList,
  };
};

export const branchFieldDetails = ({
  selectType = SELECT_TYPES.SINGLE,
  idName = "_id",
  name,
  dependsOn,
}) => ({
  name,
  label2: "Branch",
  key: "branchName",
  idName,
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  filters: dependsOn || [FIELD_NAMES.ZONE],
  getList: (filters) => {
    const { batchId } = filters;

    if (batchId) return filterBranchesIfContainsBatch(batchId);
    const zone = filters[FIELD_NAMES.ZONE];
    return filterBranchesByZone(zone);
  },
});

export const classFieldDetails = ({
  selectType = SELECT_TYPES.SINGLE,
  name,
  dependsOn,
}) => ({
  name,
  label2: "Class",
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  key: "label",
  idName: "value",
  filters: dependsOn || [FIELD_NAMES.BRANCH],
  getList: (filters) => {
    // filter1 branch
    const branch = filters[FIELD_NAMES.BRANCH] || filters.branch;

    return filterClassesByBranch(branch);
  },
});

export const batchFieldDetails = ({
  selectType = SELECT_TYPES.SINGLE,
  name,
  dependsOn,
}) => ({
  name,
  label2: "Batch",
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  key: "batchName",
  filters: dependsOn || [FIELD_NAMES.CLASS, FIELD_NAMES.BRANCH],
  getList: (filters) => {
    // filter1 -> branch, filter2 -> class
    const branch = filters[FIELD_NAMES.BRANCH] || filters.branch;
    const grade = filters[FIELD_NAMES.CLASS] || filters.class;
    return filterBatchesByBranchesAndGrades(branch, grade);
  },
});

export const sectionFieldDetails = ({
  selectType = SELECT_TYPES.SINGLE,
  name,
  dependsOn,
}) => ({
  name,
  label2: "Section",
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  idName: "id",
  key: "sectionName",
  filters: dependsOn || [
    FIELD_NAMES.BATCH,
    FIELD_NAMES.CLASS,
    FIELD_NAMES.BRANCH,
  ],
  getList: (filters) => {
    // filter1 -> branch, filter2 -> class
    const branch = filters[FIELD_NAMES.BRANCH] || filters.branch;
    const grade = filters[FIELD_NAMES.CLASS] || filters.class;
    const batch = filters[FIELD_NAMES.BATCH] || filters.batch;
    return filterSectionsByBranchClassAndBatch(branch, grade, batch);
  },
});

export const tests = ({
  name,
  selectType,
  offlineAnalysis = false,
  dependsOn,
}) => ({
  name,
  label2: "tests",
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  idName: "test_name",
  key: "testDisplayName",
  filters: dependsOn || [FIELD_NAMES.BATCH, FIELD_NAMES.BRANCH],
  getList: (filters) => {
    const batch = filters[FIELD_NAMES.BATCH];
    const branch = filters[FIELD_NAMES.BRANCH];
    return filterTestsByBatch(batch, offlineAnalysis, branch);
  },
});

export const courseFieldDetails = ({ name, selectType, ...rest }) => ({
  name,
  label2: "Course",
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  ...rest,
});

export const dateDetails = ({ name }) => ({
  name,
  label2: name,
  type: FIELD_TYPES.DATE,
});
export const muidateDetails = ({ name }) => ({
  name,
  label2: name,
  type: FIELD_TYPES.MUI_DATE,
});
export const fromdateDetails = ({ name }) => ({
  name,
  label2: name,
  type: FIELD_TYPES.FROM_DATE,
});
export const todateDetails = ({ name }) => ({
  name,
  label2: name,
  type: FIELD_TYPES.TO_DATE,
});

export const textField = ({ name = "textField", ...props }) => ({
  name,
  label2: name,
  type: FIELD_TYPES.TEXT_FIELD,
  ...props,
});

export const time = ({ name = "time" }) => ({
  name,
  label2: name,
  type: FIELD_TYPES.TIME,
});

export const checkBox = ({ name = "checkBox", ...props }) => ({
  name,
  label2: name,
  label: name,
  type: FIELD_TYPES.CHECKBOX,
  ...props,
});

export const switchField = ({ name = "switch" }) => ({
  name,
  label2: name,
  type: FIELD_TYPES.SWITCH,
});
export const radioField = ({ name = "radio" }) => ({
  name,
  label2: name,
  type: FIELD_TYPES.RADIO,
});
export const dropdownField = ({
  name,
  selectType = SELECT_TYPES.SINGLE,
  idName,
  key,
  ...rest
}) => ({
  name,
  label2: name,
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  idName,
  key,
  ...rest,
});

export const hostelFieldDetails = ({
  selectType = SELECT_TYPES.SINGLE,
  name,
}) => ({
  name,
  label2: "Hostel",
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  idName: "hostelId",
  key: "hostelName",
  filters: [FIELD_NAMES.BRANCH],
  getList: (filters) => {
    // filter1 -> branch, filter2 -> class
    const branch = filters[FIELD_NAMES.BRANCH];
    return filterHostelByBranch(branch);
  },
});

export const bankFieldDetails = ({
  selectType = SELECT_TYPES.SINGLE,
  name,
}) => {
  const zonesList = useSelector(selectZonesList);
  return {
    name,
    label2: "Bank Name",
    key: "zoneName", // after creation of bank replace it
    type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
    getList: () => zonesList, // after creation bank replace it
  };
};
export const bankAccountFieldDetails = ({
  selectType = SELECT_TYPES.SINGLE,
  name,
}) => {
  const zonesList = useSelector(selectZonesList); // repalce it by accountg no's
  return {
    name,
    label2: "Bank Account NO",
    key: "zoneName", // after creation of bank replace it
    type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
    getList: () => zonesList, // after creation bank replace it
  };
};

export const installmentsFieldDetails = ({
  selectType = SELECT_TYPES.SINGLE,
  name,
}) => {
  const zonesList = useSelector(selectZonesList); // repalce it by accountg no's
  return {
    name,
    label2: "Installments",
    key: "zoneName", // repalce it after fee creation
    type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
    getList: () => zonesList, // repalce it after fee creation
  };
};

export const feeTypeFieldDetails = ({ selectType = SELECT_TYPES.SINGLE }) => ({
  label1: "Select Fee Type",
  label2: "Fee Type",
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  // idName: "hostelId",
  // key: "hostelName",
  filters: [FIELD_NAMES.BRANCH],
  getList: (filters) => {
    // filter1 -> branch, filter2 -> class
    const branch = filters[FIELD_NAMES.BRANCH];

    return filterHostelByBranch(branch);
  },
});

export const counsellorFieldDetails = ({
  name = "counsellor",
  selectType = SELECT_TYPES.SINGLE,
}) => ({
  name,
  label2: "counsellor",
  key: "fullName",
  filters: [FIELD_NAMES.BRANCH],
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  getList: (filters) => {
    // filter1 -> branch, filter2 -> class
    const branch = filters[FIELD_NAMES.BRANCH];
    return filterCounsellorsByBranch(branch);
  },
});
export const accessLevelsField = ({
  name = "accessLevels",
  selectType = SELECT_TYPES.SINGLE,
}) => ({
  name,
  label2: "accessLevels",
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  getList: () => {
    const user = store.get("user");
    const isCampaignUser = user?.designation === "CAMPAIGN";

    return generateDropDownList(
      isCampaignUser ? ["branch"] : getObjectKeys(roles)
    );
  },
});
export const designationField = ({
  name = "Designation",
  selectType = SELECT_TYPES.SINGLE,
  phase,
}) => ({
  name,
  label2: "Designation",
  filters: [FIELD_NAMES.ACCESS_LEVEL],
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  phase,
  getList: (filters) => {
    const accessLevels = filters[FIELD_NAMES.ACCESS_LEVEL];
    const list = makeArr(accessLevels);
    const user = store.get("user");
    const isCampaignUser = user?.designation === "CAMPAIGN";

    if (isCampaignUser) {
      return generateDropDownList(["PRO"]);
    }
    if (!accessLevels)
      return getObjectKeys(roles).reduce(
        (a, c) => [...a, ...generateDropDownList(getObjectValues(roles[c]))],
        []
      );
    return generateDropDownList(
      list.reduce((a, c) => [...a, ...getObjectValues(roles[c.value])], [])
    );
  },
});

export const employeesField = ({
  name = "Referral",
  selectType = SELECT_TYPES.SINGLE,
  phase,
}) => ({
  name,
  label2: "Referral",
  filter: [FIELD_NAMES.DESIGNATION],
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  key: "fullName",
  idName: "employeeId",
  filters: ["designation"],
  phase,
  getList: (filters) => {
    const designation = filters?.designation;
    const branch = filters?.branch?._id;
    return filterEmployeesByDesignation(designation, branch, phase);
  },
});

export const floorFieldDetails = ({
  selectType = SELECT_TYPES.SINGLE,
  name,
}) => ({
  name,
  label2: "floor",
  type: `${FIELD_TYPES.DROPDOWN}_${selectType}`,
  idName: "hostelId",
  key: "hostelName",
  filters: [FIELD_NAMES.BRANCH],
  getList: (filters) => {
    // filter1 -> branch, filter2 -> class
    const branch = filters[FIELD_NAMES.BRANCH];
    return filterHostelByBranch(branch);
  },
});
