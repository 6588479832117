import * as actionType from "../actions/actions";

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  error: null,
};
// eslint-disable-next-line import/prefer-default-export
export const createTestPaperReducer = (createTest = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SAVE_PAPER_LOADING:
      return {
        ...createTest,
        isLoading: true,
      };
    case actionType.SAVE_PAPER_SUCCESS:
      return {
        ...createTest,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_SAVE_PAPER:
      return {
        ...createTest,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_SAVE_PAPER:
      return INITIAL_STATE;

    default:
      return createTest;
  }
};
