// retrieve list of video urls constants
export const FETCH_ALL_VIDEOS_URL_LOADING = "FETCH_ALL_VIDEOS_URL_LOADING";
export const FETCH_ALL_VIDEOS_URL_SUCCESS = "FETCH_ALL_VIDEOS_URL_SUCCESS";
export const CATCH_ERROR_VIDEOS_URL = "CATCH_ERROR_VIDEOS_URL";
export const CLEAR_ERROR_VIDEOS_URL = "CLEAR_ERROR_VIDEOS_URL";
// retrieve cumulative attendance
export const FETCH_ATTENDANCE_LOADING = "FETCH_ATTENDANCE_LOADING";
export const FETCH_ATTENDANCE_SUCCESS = "FETCH_ATTENDANCE_SUCCESS";
export const CATCH_ERROR_ATTENDANCE = "CATCH_ERROR_ATTENDANCE";
export const CLEAR_ERROR_ATTENDANCE = "CLEAR_ERROR_ATTENDANCE";
