import * as actionType from "./actions";
// fetch questions from question bank
export const fetchAllVideos = (query) => ({
  type: actionType.FETCH_ALL_VIDEOS_URL_LOADING,
  query,
});
export const fetchAllVideosSuccess = (allVideos) => ({
  type: actionType.FETCH_ALL_VIDEOS_URL_SUCCESS,
  payload: allVideos,
});

// fetch class details (subjects, topics and subtopics)
export const fetchAttendance = (payload) => ({
  type: actionType.FETCH_ATTENDANCE_LOADING,
  payload,
});
export const fetchAttendanceSuccess = (attendance) => ({
  type: actionType.FETCH_ATTENDANCE_SUCCESS,
  payload: attendance,
});

/*  -------------api error handling action creators----------------- */
export const catchErrorAllVideos = (error) => ({
  type: actionType.CATCH_ERROR_VIDEOS_URL,
  payload: error,
});
export const catchErrorAttendance = (error) => ({
  type: actionType.CATCH_ERROR_ATTENDANCE,
  payload: error,
});

/* ------------clear error from store if any on component unmount------------ */

export const clearErrorAllVideos = () => ({
  type: actionType.CLEAR_ERROR_VIDEOS_URL,
});
export const clearErrorAttendance = () => ({
  type: actionType.CLEAR_ERROR_ATTENDANCE,
});
