import * as actionType from "./actions";
// fetch questions from question bank
export const fetchQuestionBank = (body) => ({
  type: actionType.FETCH_QUESTIONBANK_LOADING,
  body,
});
export const fetchQuestionBankSuccess = (questionBank) => ({
  type: actionType.FETCH_QUESTIONBANK_SUCCESS,
  payload: questionBank,
});

// fetch class details (subjects, topics and subtopics)
export const fetchClassWiseSubjects = (query) => ({
  type: actionType.FETCH_CLASS_DETAILS_LOADING,
  query,
});
export const fetchClassWiseSubjectsSuccess = (classDetails) => ({
  type: actionType.FETCH_CLASS_DETAILS_SUCCESS,
  payload: classDetails,
});

// fetch single test paper details
export const fetchSingleTestPaper = (testId) => ({
  type: actionType.GET_SINGLE_PAPER_LOADING,
  testId,
});
export const fetchSingleTestPaperSuccess = (singleTestPaperDetails) => ({
  type: actionType.GET_SINGLE_PAPER_SUCCESS,
  payload: singleTestPaperDetails,
});

// save created test paper
export const saveCreatedTestPaper = (body) => ({
  type: actionType.SAVE_PAPER_LOADING,
  body,
});
export const saveCreatedTestPaperSuccess = (test) => ({
  type: actionType.SAVE_PAPER_SUCCESS,
  payload: test,
});

// query to fetch questions from question bank
export const saveQueryToStore = (query) => ({
  type: actionType.SAVE_QUERY_TO_STORE,
  query,
});

export const clearQueryFromStore = () => ({
  type: actionType.CLEAR_QUERY_FROM_STORE,
});

// save question ids of selected questions to store
export const saveSelectedQuestionsToStore = (question) => ({
  type: actionType.SAVE_SELECTED_QUESTIONS_TO_STORE,
  payload: question,
});

export const clearSelectedQuestionsFromStore = (question) => ({
  type: actionType.REMOVE_SELECTED_QUESTIONS_FROM_STORE,
  payload: question,
});

export const clearAllSelectedQuestionsFromStore = () => ({
  type: actionType.REMOVE_ALL_SELECTED_QUESTIONS_FROM_STORE,
});

export const addPartialPaper = (data) => ({
  type: actionType.ADD_PARTIAL_PAPER,
  payload: data,
});
// action creator for marking schema
export const fetchMarkingSchema = (body) => ({
  type: actionType.GET_MARKING_SCHEMA_LOADING,
  body,
});
export const fetchMarkingSchemaSuccess = (schema) => ({
  type: actionType.GET_MARKING_SCHEMA_SUCCESS,
  payload: schema,
});

/*  -------------api error handling action creators----------------- */
export const catchErrorQuestionBank = (error) => ({
  type: actionType.CATCH_ERROR_FETCH_QB,
  payload: error,
});
export const catchErrorSubjectDetails = (error) => ({
  type: actionType.CATCH_ERROR_FETCH_CLASS,
  payload: error,
});
export const catchErrorSubmitPaper = (error) => ({
  type: actionType.CATCH_ERROR_SAVE_PAPER,
  payload: error,
});

export const catchErrorSinglePaper = (error) => ({
  type: actionType.CATCH_ERROR_SINGLE_PAPER,
  payload: error,
});
export const catchErrorSaveTestPaper = (error) => ({
  type: actionType.CATCH_ERROR_SAVE_PAPER,
  payload: error,
});
export const catchErrorMarkingSchema = (error) => ({
  type: actionType.CATCH_ERROR_MARKING_SCHEMA,
  payload: error,
});
/* ------------clear error from store if any on component unmount------------ */

export const clearErrorQuestionBank = () => ({
  type: actionType.CLEAR_ERROR_FETCH_QB,
});
export const clearErrorSubmitPaper = () => ({
  type: actionType.CLEAR_ERROR_SAVE_PAPER,
});

export const clearErrorSinglePaper = () => ({
  type: actionType.CLEAR_ERROR_SINGLE_PAPER,
});
export const clearErrorGetSubject = () => ({
  type: actionType.CLEAR_ERROR_FETCH_CLASS,
});
export const clearErrorSavePaper = () => ({
  type: actionType.CLEAR_ERROR_SAVE_PAPER,
});
export const clearErrorMarkingSchema = () => ({
  type: actionType.CLEAR_ERROR_MARKING_SCHEMA,
});
