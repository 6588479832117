const getJumbledArray = (a = []) => {
  // eslint-disable-next-line no-plusplus
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const generateSet = (qNos = {}) => {
  let generatedCode = [];
  Object.keys(qNos).forEach((sub) => {
    Object.keys(qNos[sub]).forEach((type) => {
      generatedCode = [...generatedCode, ...getJumbledArray(qNos[sub][type])];
    });
  });
  return generatedCode.toString();
};

// create obj to store section wise question numbers
const createSectionMap = (schema = {}) => {
  const qNos = {};
  const qType = {};
  // get subjects and question types
  Object.keys(schema)
    .filter((key) => Number(key))
    .forEach((item) => {
      qNos[schema[item].subject] = true;
      qType[schema[item].type] = true;
    });
  // get question numbers,question type wise for each subjects
  Object.keys(qNos).forEach((sub) => {
    const sections = {};
    Object.keys(qType).forEach((type) => {
      const arr = [];
      Object.keys(schema).forEach((item) => {
        if (schema[item].subject === sub && schema[item].type === type) {
          arr.push(item);
        }
      });
      sections[type] = arr;
    });
    qNos[sub] = sections;
  });
  return qNos;
};
export { generateSet, createSectionMap };
