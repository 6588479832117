export const IFRAME_ADMIN_CONTAINER_ID = "IFRAME_ADMIN_CONTAINER_ID";

export const PHASE_KEYS = {
  enquiry: "enquiryCode",
  application: "applicationNo",
  admission: "admissionNo",
};

export const MISC_FEE_TYPES = [
  "SCHOLARSHIP_APPLY",
  "SCHOLARSHIP_RENEWAL",
  "ACCOUNT",
  "SUPPLY_FEE",
  "PARTY",
  "EVS",
  "LAB_FEE",
  "UNIFORM",
  "OTHERS",
  "TC_FEE",
  "EXAM_FINE",
  "ATTEND_FEE",
  "EXAM_FINE_INTERNAL",
  "GROUP_CHANGE",
  "JEE_MAINS",
  "JEE_ADVANCE",
  "NEET",
  "RECORDS_AND_MANUALS",
  "IMPROVEMENT_FEE",
  "RE_VALUATION_FEE",
  "IIT_FEE",
  "IIT_MATERIAL",
  "TC_ADMISSION_FEE",
  "STUDENT_ID_CARDS",
  "TOUR_AMOUNT",
  "BELT_FEE",
  "CBSE_EXAM_FEE",
  "DHOBI_FEE",
  "HOT_WATER_FEE",
  "STUDY_MATERIAL_FEE",
  "MISC_TUTION_FEE",
  "ID_CARD",
  "CBSE_REGISTRATION_FEE",
  "STUDY_MATERIAL_IIT",
  "STUDY_MATERIAL_TITAN",
  "UNIFORM_GIRLS_RESI",
  "UNIFORM_BOYS_RESI",
  "UNIFORM_GIRLS_DAY",
  "UNIFORM_BOYS_DAY",
  "ONLINE_EXAM_FEE",
  "IPE_EXAMINATION_FEE",
  "IPE_TRANSPORTATION_CHARGES_RESIDENTIAL_ONLY",
  "IPE_SUPLI_EXAMINATION_FEE",
  "AC_CHARGES_RESIDENTIAL_ONLY",
  "MESS_CHARGES",
  "BELT_FEE",
  "DIARY_FEE",
  "CBSE_EXAMINATION_FEE",
  "CBSE_REGISTRATION_FEE",
  "CHECK_POINT_FEE",
  "CBSE_PLUS_ONE_CLASS_FEE",
  "APRON_FEE",
  "UNIFORM_KIT_FEE",
  "UNIFORM_MATERIAL_FEE",
  "RECORD_BOOK_FEE",
  "DAY_CARE_FEE",
  "IPE_LATEFEE",
  "SINGLE_OCCUPANCY_NAC",
  "DOUBLE_OCCUPANCY_NAC",
  "SINGLE_OCCUPANCY_AC",
  "DOUBLE_OCCUPANCY_AC",
  "TRIPLE_OCCUPANCY",
  "OTHER_THAN_TUITION_FEE_AND_HOSTEL_FEE",
];

export const PAYMENT_STATUS = {
  Ok: "Success",
  F: "Failure",
  To: "Timed Out",
  Pending: "Pending",
  pending: "Pending",
  revert: "Reverted",
  Cancelled: "Cancelled",
  success: "success",
};

export const FORM_MODES = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  UPDATE: "UPDATE",
};

export const PAYMENT_MODE = {
  CA: "Cash",
  CC: "Credit card",
  DC: "Debit Card",
  CQ: "Cheque",
  NB: "Net Banking",
  UPI: "UPI",
  BT_ADJUST: "Branch transfer adjustment",
  WIB_ADJUST: "Within branch transfer adjustment",
  CD_ADJUST: "Caution deposit adjustment",
  ONLINE: "Online",
  Online: "Online",
  SWIPE: "Credit/Debit Card swipe",
};
export const ReportPAYMENT_MODEs = {
  CA: "Cash",
  CC: "Credit card",
  DC: "Debit Card",
  CQ: "Cheque",
  NB: "Net Banking",
  UPI: "UPI",
  // BT_ADJUST: "Branch transfer adjustment",
  // WIB_ADJUST: "Within branch transfer adjustment",
  // CD_ADJUST: "Caution deposit adjustment",
  ONLINE: "Online",
  // Online: "Online",
  SWIPE: "Credit/Debit Card swipe",
};
export const FIELD_TYPES = {
  DROPDOWN: "DROPDOWN",
  DROPDOWN_SINGLE: "DROPDOWN_SINGLE",
  DROPDOWN_MULTI: "DROPDOWN_MULTI",
  TEXT_FIELD: "TEST_FIELD",
  DATE: "DATE",
  TIME: "TIME",
  CHECKBOX: "CHECKBOX",
  SWITCH: "SWITCH",
  DROPDOWN_FIELD: "DROPDOWN_FIELD",
  RADIO: "RADIO",
  MUI_DATE: "MUI_DATE",
  FROM_DATE: "FROM_DATE",
  TO_DATE: "TO_DATE",
};

export const SELECT_TYPES = {
  SINGLE: "SINGLE",
  MULTI: "MULTI",
};

export const FIELD_NAMES = {
  USER_ID: "USER_ID",
  ACADEMIC_YEAR: "ACADEMIC_YEAR",
  ZONE: "ZONE",
  CLASS: "CLASS",
  COURSE: "COURSE",
  GROUP: "GROUP",
  BATCH: "BATCH",
  BRANCH: "BRANCH",
  SECTION: "SECTION",
  DATE: "DATE",
  MUI_DATE: "MUI_DATE",
  FROM_DATE: "FROM_DATE",
  TO_DATE: "TO_DATE",
  STATUS: "STATUS,",
  STAGE: "STAGE",
  COUNSELLOR: "counsellor",
  ACCESS_LEVEL: "ACCESS_LEVEL",
  DESIGNATION: "DESIGNATION",
  STATE: "STATE",
  DISTRICT: "DISTRICT",
  EMPLOYEE_ID: "EMPLOYEE_ID",
  FULL_NAME: "FULL_NAME",
  PHONE_NUMBER: "PHONE_NUMBER",
  EMAIL_ID: "EMAIL_ID",
  NUMBER_OF_PERIODS: "NUMBER_OF_PERIODS",
  ATTENDANCE_TYPE: "ATTENDANCE_TYPE",
  TIMING_NAME: "TIMING_NAME",
  HOSTEL_NAME: "HOSTEL_NAME",
  TEXT_FIELD: "TEXT_FIELD",
  TIME: "TIME",
  CHECKBOX: "CHECKBOX",
  SWITCH: "SWITCH",
  RADIO: "RADIO",
  DROPDOWN_FIELD: "DROPDOWN_FIELD",
  INSTALLMENTS: "INSTALLMENTS",
  FEE_TYPE: "FEE_TYPE",
  BANK_NAMES: "BANK_NAMES",
  CUSTOM: "CUSOTM",
  ADMISSION_TYPE: "admissionType",
  APPLICATION_STATUS: "applicationStatus",
  BLOOD_GROUP: "bloodGroup",
  MOTHER_TOUNGE: "motherTounge",
  RELIGION: "religion",
  NATIONALITY: "nationality",
  SOURCE: "source",
  GENDER: "gender",
  CASTE: "caste",
  subCaste: "subCaste",
  PHASE: "phase",
  TEST: "test",
  DURATION: "duration",
  GROUPBY: "groupBy",
  PUNCH: "punch",
  DAY: "day",
  EMPLOYEE: "employee",
  // COUNSELLOR: "COUNSELLOR",
};

export const NOTIFICATION_CATEGORIES = {
  DOWNLOAD_STUDENTS_LIST: "DOWNLOAD",
  UPDATE_ACTIVE_STATUS_OF_STUDENT: "STUDENT",
  CONCESSION_CHANGE_ON_STUDENT: "FINANCE",
  TRANSACTION_REVERT: "FINANCE",
  TRANSACTION_UPDATE: "FINANCE",
  DOWNLOAD_FEE_REPORT: "DOWNLOAD",
  BRANCH_TRANSFER: "ADMISSIONS",
};

export const INSTITUTE_IDS = {
  SR: "5e9098aaa218252d49be5a3b",
  ONESAZ: "5d679d49c136660a09596d85",
  SR_GROUP: "65772522ee64cfa8dc9851f2",
};

export const FEE_UNITS = [
  { label: "other_fees", value: "U01" },
  { label: "miscellaneous", value: "U02" },
];

export const UNITS = [
  "U01",
  "U02",
  "U03",
  "U04",
  "U05",
  "U06",
  "U07",
  "U08",
  "U09",
  "U10",
];

export const RELOCATE = {
  "sradmin.onesaz.com": "https://srgroup.onesaz.com",
};

const common_options = {
  RETAIN_AND_TRANSFER: "A",
  FULL_RETAIN: "B",
  TRANFER_IF_WITH_MAX_ADJUSTMENT: "C",
  TRANSFER_WITHOUT_ADJUSTMENT: "D",
  RETAIN_AND_EXCEMPT: "E",
  RETAIN_AND_ADJUST_TO_DIFFERENCE: "F",
};

export const BEHAVIOUR_OPTION_MAP = {
  A: "RETAIN_AND_TRANSFER",
  C: "TRANFER_IF_WITH_MAX_ADJUSTMENT",
  D: "TRANSFER_WITHOUT_ADJUSTMENT",
  B: "FULL_RETAIN",
  E: "RETAIN_AND_EXCEMPT",
  F: "RETAIN_AND_ADJUST_TO_DIFFERENCE",
};

export const BT_BEHAVIOUR_OPTIONS = {
  ...common_options,
};

export const WBT_BEHAVIOUR_OPTIONS = {
  ...common_options,
};
