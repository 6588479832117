import { useLocation } from "react-router-dom";
import { useMemo } from "react";
// helpers
import MobileHeader from "helpers/MobileHeader";
// platform
import PlatformDetector from "utils/platform";
// material-ui
import useMediaQuery from "@mui/material/useMediaQuery";
import { startCase } from "lodash";

const MobileDynamicHeader = ({ children }) => {
  const isMobile = useMediaQuery("(max-width:992px)");
  const location = useLocation();
  const { pathname } = location;
  const { module, lastLocation, header } = useMemo(() => {
    const arr = pathname.split("/");
    const text = arr[arr.length - 1];
    const haveHeader = (() => {
      if (arr[2] === "admissions") {
        if (arr[3]) {
          if (arr[3] === "follow-up" || arr[3] === "view-student") {
            return true;
          }

          return false;
        }
        return false;
      }
      return true;
    })();
    return { module: arr[2], lastLocation: text, header: haveHeader };
  }, [pathname]);

  const sPaths = [
    "analysis",
    "communications",
    "academics",
    "questions",
    "live-classes",
    "offline-utilities",
    "settings",
    "attendance",
    "admissions",
  ];

  if (sPaths.includes(module) && (PlatformDetector.isMobile() || isMobile)) {
    return (
      <>
        {header && <MobileHeader title={startCase(lastLocation)} />}
        {children}
      </>
    );
  }
  return children;
};

export default MobileDynamicHeader;
