import * as actionType from "../actions/actions";

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  error: null,
};
// eslint-disable-next-line import/prefer-default-export
export const markingSchemaReducer = (schema = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.GET_MARKING_SCHEMA_LOADING:
      return {
        ...schema,
        isLoading: true,
      };
    case actionType.GET_MARKING_SCHEMA_SUCCESS:
      return {
        ...schema,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_MARKING_SCHEMA:
      return {
        ...schema,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_MARKING_SCHEMA:
      return INITIAL_STATE;

    default:
      return schema;
  }
};
