import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
import PageLayout from "layout/LayoutComponents/PageLayout";
import Footer from "layout/LayoutComponents/Footer";
import onesaz from "assets/onesaz_mini.png";

function InstituteLayout({
  color = "info",
  header,
  title,
  description,
  image,
  top,
  ifLogoRequired = false,
  children,
}) {
  return (
    <PageLayout
      sx={{
        backgroundColor: "#eff2f4",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        background: "linear-gradient(145deg, #f0f4f8 0%, #e3eaef 100%)",
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        sx={{
          flex: 1,
          margin: 0,
          padding: { xs: 2, md: 4 },
          gap: { xs: "2rem", md: "3rem" },
        }}
      >
        {/* Left content grid */}
        <Grid item xs={11} sm={8} md={5} xl={3}>
          {ifLogoRequired && (
            <SoftBox
              mx={3}
              mt={3}
              component="img"
              src={onesaz}
              alt="UI Logo"
              width="9rem"
            />
          )}
          <SoftBox mt={top}>
            <SoftBox px={3}>
              {!header ? (
                <>
                  <SoftBox mb={1}>
                    <SoftTypography
                      variant="h3"
                      fontWeight="bold"
                      color={color}
                      textGradient
                    >
                      {title}
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography
                    variant="body2"
                    fontWeight="regular"
                    color="text"
                  >
                    {description}
                  </SoftTypography>
                </>
              ) : (
                header
              )}
            </SoftBox>
            <SoftBox p={3}>{children}</SoftBox>
          </SoftBox>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            justifyContent: "center",
            perspective: "1000px",
            padding: "1rem",
          }}
        >
          <SoftBox
            right={{ md: "-6rem", xl: "-10rem" }}
            sx={{
              position: "relative",
              width: "fit-content",
              height: "fit-content",
              overflow: "hidden",
            }}
          >
            {/* Main image with hover effect */}
            <SoftBox
              component="img"
              src={image}
              alt="background"
              sx={{
                display: "block",
                maxWidth: "100%",
                height: "auto",
                maxHeight: "80vh",
                objectFit: "contain",
                position: "relative",
                zIndex: 1,
                transition: "all 0.3s ease-in-out",
                cursor: "pointer",
                filter: "brightness(95%)",
                "&:hover": {
                  transform: "scale(0.85) translateY(10px)",
                  filter: "brightness(105%)",
                  boxShadow: "0 10px 20px rgba(0,0,0,0.2)",
                },
              }}
            />
          </SoftBox>
        </Grid>
      </Grid>
      <Footer />
    </PageLayout>
  );
}

InstituteLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  color: "info",
  top: 10,
};

InstituteLayout.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string.isRequired,
  top: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default InstituteLayout;
