import { call, put, takeLatest } from "redux-saga/effects";
import {
  getAllVideos,
  getCumulativeAttendance,
} from "../../../../APIs/apis_test_creation/attendanceAnalysisService";
import {
  catchErrorAllVideos,
  catchErrorAttendance,
  fetchAllVideos,
  fetchAllVideosSuccess,
  fetchAttendance,
  fetchAttendanceSuccess,
} from "./actionCreators";

function* getAllVideosUrl(api_payload) {
  try {
    const allVideos = yield call(getAllVideos, api_payload.query);
    const videosURL = yield allVideos.data;
    yield put(fetchAllVideosSuccess(videosURL.Items ? videosURL.Items : []));
  } catch (error) {
    yield put(catchErrorAllVideos(error.message));
  }
}
function* getAttendance(api_payload) {
  try {
    const attendance = yield call(getCumulativeAttendance, api_payload.payload);
    const cumulativeAttendance = yield attendance.data;
    yield put(fetchAttendanceSuccess(cumulativeAttendance));
  } catch (error) {
    yield put(catchErrorAttendance(error.message));
  }
}
function* attendanceSagaWatcher() {
  yield takeLatest(fetchAllVideos().type, getAllVideosUrl);
  yield takeLatest(fetchAttendance().type, getAttendance);
}

export default attendanceSagaWatcher;
