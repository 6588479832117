import { useLocation, useNavigate } from "react-router-dom";
// mui
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
// myComponents
import SoftBox from "myComponents/SoftBox";
// assets
import homeIcon from "assets/svgs/home.svg";
import finance from "assets/svgs/finance.svg";
import admissions from "assets/svgs/admissions.svg";
import profile from "assets/svgs/profile.svg";
// fun
import pxToRem from "assets/theme/functions/pxToRem";

// Styled component for BottomNavigationAction
const StyledBottomNavigationAction = styled(BottomNavigationAction)(
  ({ theme }) => ({
    "& .MuiBottomNavigationAction-label": {
      color: theme.palette.white.main,
    },
  })
);

const BottomNavigationBar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return [
    "/sign-in",
    "/register",
    "/exam-analysis",
    "/exam-portal",
    "/instructionadv",
    "/instructionneet",
    "/instruction",
  ].includes(location.pathname) ? null : (
    <>
      <Paper
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }}
        elevation={3}
        color="primary"
      >
        <SoftBox bgColor="info">
          <BottomNavigation showLabels sx={{ background: "transparent" }}>
            <StyledBottomNavigationAction
              onClick={() => navigate("/app")}
              label="Home"
              value="home-page"
              icon={<img src={homeIcon} alt="home-page" />}
            />
            <StyledBottomNavigationAction
              onClick={() => navigate("/app/finance")}
              label="Finance"
              value="finance"
              icon={<img src={finance} alt="finance-page" />}
              color="white"
            />
            <StyledBottomNavigationAction
              onClick={() => navigate("/app/admissions")}
              label="Admissions"
              value="admissions"
              icon={<img src={admissions} alt="admissions-page" />}
            />
            <StyledBottomNavigationAction
              onClick={() => navigate("/app/profile")}
              label="Profile"
              value="profile"
              icon={<img src={profile} alt="profile-page" />}
            />
          </BottomNavigation>
        </SoftBox>
      </Paper>
      <SoftBox
        height={`${pxToRem(56)}`}
        width="100%"
        bgColor="newUi.background"
      />
    </>
  );
};

export default BottomNavigationBar;
