import axios from "../baseAxios";

export async function getAllVideos(query = "") {
  try {
    const data = await axios.get(
      `/live/getAllVideos?instituteId=${query}&ifAttendance=attendance`
    );
    return data;
  } catch (error) {
    return error;
  }
}
export async function getCumulativeAttendance(payload) {
  try {
    const data = await axios.post(
      `/live/getCumulativeAttendance?export=${payload.export}`,
      payload.body
    );
    return data;
  } catch (error) {
    return error;
  }
}
