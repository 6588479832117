import { call, put, takeLatest } from "redux-saga/effects";
import {
  createSectionMap,
  generateSet,
} from "views/TestCreationRepo/utils/codeGeneration";
import {
  getClassDetails,
  getMarkingSchema,
  getQuestionFromQB,
  getSingleTestPaperAPI,
  saveTestPaper,
} from "../../../APIs/apis_test_creation/testCreationServices";

import {
  catchErrorMarkingSchema,
  catchErrorQuestionBank,
  catchErrorSaveTestPaper,
  catchErrorSinglePaper,
  catchErrorSubjectDetails,
  fetchClassWiseSubjects,
  fetchClassWiseSubjectsSuccess,
  fetchMarkingSchema,
  fetchMarkingSchemaSuccess,
  fetchQuestionBank,
  fetchQuestionBankSuccess,
  fetchSingleTestPaper,
  fetchSingleTestPaperSuccess,
  saveCreatedTestPaper,
  saveCreatedTestPaperSuccess,
} from "./actionCreators";

function* getQuestionBank(api_payload) {
  // console.log({ api_payload });
  try {
    const payload = {
      ...api_payload.body,
      // eslint-disable-next-line no-restricted-globals
      page: isNaN(Number(api_payload.body.page)) ? 1 : api_payload.body.page,
    };
    const questionFrmQB = yield call(getQuestionFromQB, payload);
    const allQuestions = yield questionFrmQB.data;
    // console.log(questionFrmQB);
    yield put(fetchQuestionBankSuccess(allQuestions));
  } catch (error) {
    yield put(catchErrorQuestionBank(error.message));
  }
}
function* getClassWiseSubjects(api_payload) {
  try {
    const subjectDetails = yield call(getClassDetails, api_payload.query);
    const allSubjectsDetails = yield subjectDetails.data;
    yield put(fetchClassWiseSubjectsSuccess(allSubjectsDetails));
  } catch (error) {
    yield put(catchErrorSubjectDetails(error.message));
  }
}

function* getSingleTestPaper(api_payload) {
  try {
    const singleTestPaper = yield call(
      getSingleTestPaperAPI,
      api_payload.testId
    );
    const testPaperDetail = yield singleTestPaper.data;
    yield put(fetchSingleTestPaperSuccess(testPaperDetail));
  } catch (error) {
    yield put(catchErrorSinglePaper(error.message));
  }
}
function* saveTestPaperToDb(api_payload) {
  try {
    let generatedCodes = {};

    const { questions = [], testDetails = {} } = api_payload.body;
    const answers = {};
    questions.forEach((item) => {
      answers[item.question_number] = item.answer;
    });
    const CODES = questions.map((item) => item.question_number).join(",");
    const [CodeA] = Array(1).fill(CODES);
    // creaTE SCHEMA
    let testSchema;
    const schemaJSON = {
      numberOfQuestions: 30,
    };
    const noOfQuestions = questions.length;
    schemaJSON.numberOfQuestions = noOfQuestions;
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < noOfQuestions; index++) {
      schemaJSON[String(index + 1)] = {
        type:
          questions[index] && questions[index].questionType
            ? questions[index].questionType
            : "single",
        subject:
          questions[index] && questions[index].subject
            ? questions[index].subject
            : "Physics",
      };
      testSchema = schemaJSON;
    }
    // get section wise questions set
    const qNosMap = createSectionMap(testSchema);
    generatedCodes = {
      B: generateSet(qNosMap),
      C: generateSet(qNosMap),
      D: generateSet(qNosMap),
      E: generateSet(qNosMap),
      F: generateSet(qNosMap),
      G: generateSet(qNosMap),
      H: generateSet(qNosMap),
      I: generateSet(qNosMap),
      J: generateSet(qNosMap),
    };
    console.log({
      questions,
      test_details: {
        ...testDetails,
        answers,
        CodeA,
        generatedCodes,
        name: testDetails.test_name,
      },
    });

    const savePaper = yield call(saveTestPaper, {
      questions,
      test_details: {
        ...testDetails,
        answers,
        CodeA,
        CodeB: generatedCodes.B,
        CodeC: generatedCodes.C,
        CodeD: generatedCodes.D,
        CodeE: generatedCodes.E,
        CodeF: generatedCodes.F,
        CodeG: generatedCodes.G,
        CodeH: generatedCodes.H,
        CodeI: generatedCodes.I,
        CodeJ: generatedCodes.J,
        name: testDetails.test_name,
      },
    });
    const savedPaperData = yield savePaper.data;
    yield put(saveCreatedTestPaperSuccess(savedPaperData));
  } catch (error) {
    yield put(catchErrorSaveTestPaper(error.message));
  }
}
function* markingSchema(api_payload) {
  try {
    const schema = yield call(getMarkingSchema, api_payload.body);
    const getSchema = yield schema.data;
    const questionSchema = {};
    Object.keys(getSchema.output).forEach((key) => {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(key)) {
        questionSchema[key] = getSchema.output[key];
      }
    });
    yield put(fetchMarkingSchemaSuccess({ ...getSchema, questionSchema }));
  } catch (error) {
    yield put(catchErrorMarkingSchema(error.message));
  }
}
function* testCreationSagaWatcher() {
  yield takeLatest(fetchQuestionBank().type, getQuestionBank);
  yield takeLatest(fetchClassWiseSubjects().type, getClassWiseSubjects);
  yield takeLatest(fetchSingleTestPaper().type, getSingleTestPaper);
  yield takeLatest(saveCreatedTestPaper().type, saveTestPaperToDb);
  yield takeLatest(fetchMarkingSchema().type, markingSchema);
}

export default testCreationSagaWatcher;
