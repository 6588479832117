import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import Popper from "@mui/material/Popper";
import { useTheme, styled } from "@mui/material/styles";
import { VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Icon from "@mui/material/Icon";
// myComponents
import SoftTypography from "myComponents/SoftTypography";
import SoftBox from "myComponents/SoftBox";
// styles
import useStyles from "components/FormikFieldArrayComponents/styles";
// local
import { RequiredLabel } from ".";

const LISTBOX_PADDING = 8; // px

function renderRow(props) {
  const { data, index, style } = props;
  const dataSet = data[index];
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  };

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[3] || ""}
    </Typography>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
  const ref = React.useRef(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef((props, ref) => {
  const { children, ...other } = props;
  const itemData = [];
  children.forEach((item) => {
    itemData.push(item);
    itemData.push(...(item.children || []));
  });

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"), {
    noSsr: true,
  });
  const itemCount = itemData.length;
  const itemSize = smUp ? 36 : 48;

  const getChildSize = () => itemSize;

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize;
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemCount);

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    "& ul": {
      padding: 0,
      margin: 0,
    },
  },
});

export default function AutocompleteWithVirtual({
  list,
  selected,
  handleSelect,
  label,
  required = false,
  optionLabelPropName,
  optionValuePropName,
  textFieldProps,
  helperText,
  noHeaderLabel,
  getLabel,
  simpleOptions = false,
  ...rem
}) {
  const addProps = !simpleOptions
    ? {
        getOptionLabel: (option) =>
          option[optionLabelPropName || "label"] || "",
        isOptionEqualToValue: (option, item) =>
          option[optionValuePropName || "_id"] ===
          item[optionValuePropName || "_id"],
      }
    : {};
  return (
    <SoftBox width="100%">
      <Autocomplete
        options={list}
        {...addProps}
        fullWidth
        value={selected || null}
        onChange={(e, i) => {
          handleSelect(e, i);
        }}
        disableListWrap
        PopperComponent={StyledPopper}
        ListboxComponent={ListboxComponent}
        renderInput={(params) => (
          <>
            <SoftBox ml={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {label ? (
                  <SoftBox ml={0.5}>
                    <RequiredLabel
                      label={label}
                      required={required}
                      noHeaderLabel={noHeaderLabel}
                    />
                  </SoftBox>
                ) : null}
              </SoftTypography>
            </SoftBox>
            <TextField
              fullWidth
              className={useStyles().textFieldStyles}
              required={required}
              placeholder={`select ${label || ""}`}
              {...params}
              {...textFieldProps}
            />
            {helperText && (
              <SoftBox
                ml={0.5}
                mb={0.5}
                display="flex"
                jsutifyContent="center"
                alignItems="center"
              >
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                >
                  {label ? (
                    <SoftBox ml={0.5} mt={0.5}>
                      <Icon sx={{ fontSize: "12px" }}>
                        <InfoOutlinedIcon color="dark" />
                      </Icon>
                      <SoftTypography
                        variant="caption"
                        fontWeight="bold"
                        color="error"
                      >
                        {helperText}
                      </SoftTypography>
                    </SoftBox>
                  ) : null}
                </SoftTypography>
              </SoftBox>
            )}
          </>
        )}
        renderOption={(props, option, state) => [
          props,
          option,
          state.index,
          getLabel ? getLabel(option) : null,
        ]}
        {...rem}
      />
    </SoftBox>
  );
}
