import { useMemo } from "react";
import store from "store";
import { useSelector } from "react-redux";
// mui
import useMediaQuery from "@mui/material/useMediaQuery";
// platform
import PlatformDetector from "utils/platform";
// selectors
import {
  selectPrivilegesOfRoles,
  selectAttendanceConfig,
} from "redux/settings/settings.selector";
// respective modules
import organizationConfig from "config/modules/organization";
import addmissionsConfig from "config/modules/admissions";
import settingsConfig from "config/modules/settings";
import financeConfig from "config/modules/finance";
import attendanceConfig from "config/modules/attendance";
import academics from "config/modules/academics";
// import academics from "./modules/academics";
import communications from "config/modules/communications";
import analysis from "config/modules/analysis";
import questions from "config/modules/questions";
import liveClasses from "config/modules/liveClasses";
import offLine from "config/modules/offLine";
import Expenses from "config/modules/Expenses";
// import hostelConfig from "config/modules/hostel";
import transportMang from "config/modules/transportMang";
import payrollAndHr from "config/modules/payrollAndHr";
import InventoryMgt from "config/modules/InventoryMgt";
import dashboard from "config/modules/dashboard";
// import building from "config/modules/building";
import infrastructure from "config/modules/infraStructure";
// mobile
import MobileRoutes from "config/modules/mobile/home";
// utils
import { getAllowedRoutes } from "utils/getAllowedRoutes";

const all = [
  dashboard,
  organizationConfig,
  addmissionsConfig,
  financeConfig,
  attendanceConfig,
  liveClasses,
  questions,
  analysis,
  communications,
  academics,
  offLine,
  payrollAndHr,
  InventoryMgt,
  transportMang,
  Expenses,
  // hostelConfig,
  // building,
  infrastructure,
  settingsConfig,
];

const useRoutesConfig = () => {
  const isMobile = useMediaQuery("(max-width:992px)");
  const privileges = useSelector(selectPrivilegesOfRoles);
  const attendanceConf = useSelector(selectAttendanceConfig);
  const { assignedRoles, addedPrivileges, removedPrivileges } =
    store.get("user") || {};
  const instituteId = store.get("instituteId");

  const routesConfig = useMemo(() => {
    if (PlatformDetector.isMobile() || isMobile) {
      return MobileRoutes;
    }
    return all;
  }, []);

  const screen = useMemo(() => {
    if (PlatformDetector.isMobile() || isMobile) {
      return "MOBILE";
    }
    return "DESKTOP";
  }, []);

  const routes = useMemo(
    () =>
      getAllowedRoutes(
        routesConfig,
        assignedRoles,
        privileges,
        attendanceConf,
        instituteId,
        addedPrivileges,
        removedPrivileges
      )
        .map((item) => {
          if (
            getAllowedRoutes(
              item.children,
              assignedRoles,
              privileges,
              attendanceConf,
              instituteId,
              addedPrivileges,
              removedPrivileges
            )?.filter((it) => !it?.noUiLink).length === 0
          ) {
            return null;
          }
          return {
            type: "collapse",
            name: item.title,
            key: item.path,
            route: item.path,
            icon: item.icon,
            component: item.component,
            noCollapse: false,
            children: getAllowedRoutes(
              item.children,
              assignedRoles,
              privileges,
              attendanceConf,
              instituteId,
              addedPrivileges,
              removedPrivileges
            ),
          };
        })
        .filter((item) => item),
    [addedPrivileges, removedPrivileges, privileges, routesConfig]
  );

  return { routes, routesConfig, screen };
};

export default useRoutesConfig;
