import flatten from "lodash/flatten";
// import store from "store";
// mui
import AccountBoxIcon from "@mui/icons-material/AccountBox";
// Helpers
import lazy from "helpers/LazyImport";
// features
import features from "config/features";
// icon
// body layout
import Container from "../../layout/simpleContainer";

// const DailyAttendance = lazy(() =>
//   import("../../views/Attendance/DailyAttendance")
// );
// const AttendanceReports = lazy(() =>
//   import("../../views/Attendance/AttendanceReports")
// );
// const AttSectionStatus = lazy(() =>
//   import("../../views/Attendance/DailyAttendance/SectionStauts")
// );
const AttSMSReport = lazy(() =>
  import("../../views/Attendance/AttSMSDeliveryReports")
);
// const SMSTasksManagement = lazy(() =>
//   import("../../views/Attendance/SMSTaskMgnt")
// );
const PunchesReport = lazy(() =>
  import("../../views/Attendance/AttendanceReports/ZKTstudent/PunchesReport")
);
const SingleStudentPunchesReport = lazy(() =>
  import(
    "../../views/Attendance/AttendanceReports/ZKTstudent/SingleStudentPunchesReport"
  )
);
const ZoomAttendance = lazy(() =>
  import("../../views/Attendance/ZoomAttendance/index")
);
const ZoomAttendanceView = lazy(() =>
  import("../../views/Attendance/ZoomAttendance/ZoomAttendanceView")
);
const AttendanePushNotificatons = lazy(() =>
  import("../../views/Attendance/AttendanePushNotificatons")
);
const ClassSchedule = lazy(() =>
  import("../../views/Attendance/ClassSchedule")
);
const ClassScheduleAttendance = lazy(() =>
  import("../../views/Attendance/ScheduleAttendance")
);
const AttReports = lazy(() => import("../../views/Attendance/AttReports"));
const AttSMS = lazy(() => import("../../views/Attendance/AttSms"));
const BranchPunches = lazy(() =>
  import("../../views/Attendance/BranchPunches")
);
const ManualAttendanceSummaries = lazy(() =>
  import("../../views/Attendance/AttReports/ManualAttSummary")
);
const MonthlyBranchAttendanceReport = lazy(() =>
  import("../../views/Attendance/AttReports/MonthlyBranchAttendanceReport")
);

const BioMeticDevices = lazy(() =>
  import("../../views/Attendance/BioDeviceAndSettings")
);

export default {
  component: <Container />,
  title: "Attendance",
  basePath: "app",
  path: "attendance",
  features: flatten(Object.values(features.attendance)),
  icon: <AccountBoxIcon />,
  children: [
    // {
    //   component: <Container />,
    //   title: "Daily Attendance",
    //   basePath: "attendance",
    //   path: "daily-attendance",
    //   features: features.attendance.daily_att,
    //   index: <DailyAttendance />,
    //   children: [
    //     {
    //       component: <AttSectionStatus />,
    //       title: "Sections status",
    //       basePath: "daily-attendance",
    //       path: "sections-status",
    //       features: features.attendance.sections_status,
    //     },
    //   ],
    // },
    {
      component: <Container />,
      title: "Zoom Attendance",
      basePath: "attendance",
      path: "zoom-reports",
      features: features.attendance.zoom_reports,
      index: <ZoomAttendance />,
      children: [
        {
          component: <ZoomAttendanceView />,
          title: "Attendance table",
          basePath: "zoom-reports",
          path: "table",
          features: features.attendance.zoom_reports,
        },
      ],
    },
    {
      component: <ClassSchedule />,
      title: "Class Schedule",
      basePath: "attendance",
      features: features.attendance.class_schedule,
      path: "class-schedule",
    },
    {
      component: <ClassScheduleAttendance />,
      title: "Give Daily Attendance",
      basePath: "attendance",
      features: features.attendance.daily_att,
      path: "class-attendance",
    },
    {
      component: <Container />,
      title: "Attendance Reports",
      basePath: "attendance",
      features: features.attendance.att_reports,
      path: "attendance-reports",
      index: <AttReports />,
      children: [
        {
          component: <ManualAttendanceSummaries />,
          title: "Attendance Summaries",
          basePath: "attendance-reports",
          features: features.attendance.att_reports,
          path: "summaries",
        },
        {
          component: <MonthlyBranchAttendanceReport />,
          title: "Monthly Attendance Report",
          basePath: "attendance-reports",
          features: features.attendance.att_reports,
          path: "monthly",
        },
      ],
    },
    {
      component: <AttSMS />,
      title: "Attendance SMS",
      basePath: "attendance",
      features: features.attendance.att_sms,
      path: "attendance-sms",
    },
    // {
    //   component: <AttendanceReports />,
    //   title: "Attendance Reports",
    //   basePath: "attendance",
    //   path: "att-reports",
    //   features: features.attendance.att_reports,
    // },
    {
      component: <AttSMSReport />,
      title: "Attendance SMS Reports",
      basePath: "attendance",
      path: "att-SMS-reports",
      features: features.attendance.att_reports,
    },
    // {
    //   component: <SMSTasksManagement />,
    //   title: "SMS Task Management",
    //   basePath: "attendance",
    //   path: "SMS-task-management",
    //   features: features.attendance.att_task_management,
    // },
    {
      component: <PunchesReport />,
      title: "Punches Report",
      basePath: "attendance",
      path: "punches-report",
      features: features.attendance.punches_report,
    },
    {
      component: <SingleStudentPunchesReport />,
      title: "Student Punches Report",
      basePath: "attendance",
      path: "single_punches_report",
      features: features.attendance.single_punches_report,
    },
    {
      component: <AttendanePushNotificatons />,
      title: "Attendance Push Notifications",
      basePath: "attendance",
      features: features.attendance.custom_time_att_push_notifications,
      path: "attendance-push-notifications",
    },
    {
      component: <BranchPunches />,
      title: "Staff Attendance",
      basePath: "attendance",
      path: "staff-attendance",
      features: ["view_staff_attendance"],
    },
    {
      component: <BioMeticDevices />,
      title: "Biometric Devices",
      basePath: "attendance",
      path: "biometric-devices",
      features: ["view_bio_devices"],
    },
  ],
};
