// myComponets
import SoftTextField from "myComponents/SoftTextField";
import SoftAutoComplete from "myComponents/SoftAutoComplete";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import SoftAutoCompleteMultiSelect from "myComponents/SoftAutoComplete/MultiSelect";
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
import AutocompleteWithVirtual from "myComponents/SoftAutoComplete/virtualize";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
// consts
import { FIELD_TYPES } from "config/globalConsts";
import { getObjectKeys } from "utils/general.utils/object.utils";

const MyFilter = ({
  options,
  details,
  name,
  value,
  setValue,
  filters,
  small,
  values,
  showDate,
  LabelFyear,
  ...props
}) => {
  const { type, getList = () => [], getOptions, ...rest } = details;

  const getItems = () => {
    if (getOptions) return getOptions(options, values);
    if (options) return options;
    if (getList) return getList(filters);
    return [];
  };

  if (type === FIELD_TYPES.TEXT_FIELD) {
    const l = name.replace(/([A-Z])/g, " $1");
    const label = l.charAt(0).toUpperCase() + l.slice(1);
    return (
      <SoftTextField
        small={small}
        name={name}
        noHeaderLabel
        value={value}
        onChange={(e) => setValue(e.target.value)}
        label={label}
        headerLabel={false}
      />
    );
  }
  if (showDate) {
    if (type === FIELD_TYPES.DATE) {
      const l = name.replace(/([A-Z])/g, " $1");
      const label = l.charAt(0).toUpperCase() + l.slice(1);
      return (
        <SoftTextField
          small={small}
          type="date"
          name={name}
          value={value || null}
          noHeaderLabel
          // max={max}
          // min={min}
          // defaultValue={new Date()}
          onChange={(e) => setValue(e.target.value)}
          label={label}
        />

        // <DatePicker
        //   value={value}
        //   name={name}
        //   inputFormat="dd/MM/yyyy"
        //   placeholder={label}
        //   onChange={(newValue) => setValue(newValue)}
        //   renderInput={(params) => (
        //     <TextField
        //       {...params}
        //       InputLabelProps={{
        //         shrink: false,
        //       }}
        //       className={useStyles().textFieldStyles}
        //     />
        //   )}
        // />
      );
    }
  }
  if (!showDate && !getObjectKeys(values).includes("duration")) {
    if (type === FIELD_TYPES.DATE) {
      const l = name.replace(/([A-Z])/g, " $1");
      const label = l.charAt(0).toUpperCase() + l.slice(1);
      return (
        <SoftTextField
          small={small}
          type="date"
          name={name}
          value={value || null}
          noHeaderLabel
          onChange={(e) => setValue(e.target.value)}
          label={label}
        />
      );
    }
  }
  if (type === FIELD_TYPES.FROM_DATE || type === FIELD_TYPES.TO_DATE) {
    const l = name.replace(/([A-Z])/g, " $1");
    const label = l.charAt(0).toUpperCase() + l.slice(1);

    return (
      <>
        <SoftBox ml={0.5} />

        <SoftBox
          maxWidth="600px"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <DatePicker
            value={value}
            name={name}
            small={small}
            // label={label || name}
            sx={{ width: "100%" }}
            inputFormat="DD/MM/YYYY"
            placeholder={label}
            maxDate={new Date()}
            // minDate={new Date(new Date().getFullYear(), 3, 1)} // April 1 of the current year
            onChange={(newValue) => setValue(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                InputLabelProps={{
                  shrink: false,
                }}
              />
            )}
          />
          {/* &nbsp;&nbsp;
          <SoftBox>
            <SoftTypography variant="button">
              {type === FIELD_TYPES.TO_DATE ? "To" : null} &nbsp;
            </SoftTypography>
          </SoftBox> */}
        </SoftBox>
      </>
    );
  }

  if (
    type === FIELD_TYPES.DROPDOWN ||
    type === FIELD_TYPES.DROPDOWN_SINGLE ||
    type === FIELD_TYPES.DROPDOWN_FIELD
  ) {
    if (props?.virtualList) {
      return (
        <SoftBox maxWidth="600px" width="100%">
          <AutocompleteWithVirtual
            selected={value || null}
            list={getItems()}
            handleSelect={(event, item) => setValue(item)}
            label={details.label2}
            filled
            small={small}
            optionLabelPropName={details.key}
            optionValuePropName={details.idName}
            textFieldProps={small ? { size: "small" } : {}}
            getLabel={props.getLabelInVirtualList}
            {...props}
            {...rest}
          />
        </SoftBox>
      );
    }
    return (
      <SoftBox maxWidth="600px" width="100%">
        <SoftAutoComplete
          selected={value || null}
          list={getItems()}
          handleSelect={(event, item) => setValue(item)}
          label={details.label2}
          filled
          noHeaderLabel
          // disableClearable
          optionLabelPropName={details.key}
          optionValuePropName={details.idName}
          textFieldProps={small ? { size: "small" } : {}}
          {...props}
          {...rest}
        />
        {LabelFyear && details?.name === "academicYear" && (
          <SoftTypography variant="button">
            &nbsp;(Financial-year)
          </SoftTypography>
        )}
      </SoftBox>
    );
  }

  if (type === FIELD_TYPES.DROPDOWN_MULTI)
    return (
      <SoftBox maxWidth="600px" width="100%">
        <SoftAutoCompleteMultiSelect
          selectedValues={value || []}
          values={options || getList(filters) || []}
          setValues={setValue}
          label={details.label2}
          noHeaderLabel
          optionLabelPropName={details.key}
          optionValuePropName={details.idName}
          textFieldProps={small ? { size: "small" } : {}}
          {...props}
          {...rest}
        />
      </SoftBox>
    );
  if (type === FIELD_TYPES.CHECKBOX)
    return (
      <SoftBox maxWidth="600px" width="100%" pl={1}>
        <FormGroup>
          <FormControlLabel
            control={<Checkbox />}
            checked={value}
            name={name}
            onChange={(event) => {
              setValue(event.target.checked);
            }}
            label={details.label2}
          />
        </FormGroup>
      </SoftBox>
    );
  return null;
};

export default MyFilter;
