import features from "config/features";
import flatten from "lodash/flatten";

export default {
  "/finance": [
    "Finance_tab",
    "view_collections_other_than_campaign",
    ...features.finance.concession_approvals,
    ...features.finance.revertTxn_approvals,
    ...features.finance.updateTxn_approvals,
    ...features.finance.custom_fee_approvals,
  ],
  "/finance/collections": ["view_collections_other_than_campaign"],
  "/finance/collections/:zoneCode": ["view_collections_other_than_campaign"],
  "/finance/collections/:zoneCode/:branchCode": [
    "view_collections_other_than_campaign",
  ],
  "/finance/make-payment": ["make_payment"],
  // "/finance/payments": ["make_payment", "reservation_fee_payment"],

  "/admissions": ["admission_tab", "followups", "view-student"],
  "/admissions/branches-strength": ["view_students"],
  "/admissions/branches-strength/class-summary": ["view_students"],
  "/admissions/branches-strength/class-summary/students-list": [
    "view_students",
  ],
  "/attendance": [
    "view_att_reports",
    "give_daily_attendance",
    "view_staff_attendance",
  ],
  "/analysis": flatten(Object.values(features.analysis)),
  "/communications": flatten(Object.values(features.communications)),
  "/academics": flatten(Object.values(features.academics)),
  "/questions": flatten(Object.values(features.questions)),
  "/live-classes": flatten(Object.values(features["live-classes"])),
  "/offline-utilities": flatten(Object.values(features?.["offline-utilities"])),
  "/settings": flatten(Object.values(features.settings)),
};
