// save query to get questions in store
export const SAVE_QUERY_TO_STORE = "SAVE_QUERY_TO_STORE";
export const CLEAR_QUERY_FROM_STORE = "CLEAR_QUERY_FROM_STORE";
// retrieve list of questions constants
export const FETCH_QUESTIONBANK_LOADING = "FETCH_QUESTIONBANK_LOADING";
export const FETCH_QUESTIONBANK_SUCCESS = "FETCH_QUESTIONBANK_SUCCESS";
export const CATCH_ERROR_FETCH_QB = "CATCH_ERROR_FETCH_QB";
export const CLEAR_ERROR_FETCH_QB = "CLEAR_ERROR_FETCH_QB";
// retrieve details of subjects class wise
export const FETCH_CLASS_DETAILS_LOADING = "FETCH_CLASS_DETAILS_LOADING";
export const FETCH_CLASS_DETAILS_SUCCESS = "FETCH_CLASS_DETAILS_SUCCESS";
export const CATCH_ERROR_FETCH_CLASS = "CATCH_ERROR_FETCH_CLASS";
export const CLEAR_ERROR_FETCH_CLASS = "CLEAR_ERROR_FETCH_CLASS";

// get question ids of selected questions from question bank
export const SAVE_SELECTED_QUESTIONS_TO_STORE =
  "SAVE_SELECTED_QUESTIONS_TO_STORE";
export const REMOVE_SELECTED_QUESTIONS_FROM_STORE =
  "REMOVE_SELECTED_QUESTIONS_FROM_STORE";
export const REMOVE_ALL_SELECTED_QUESTIONS_FROM_STORE =
  "REMOVE_ALL_SELECTED_QUESTIONS_FROM_STORE";
export const ADD_PARTIAL_PAPER = "ADD_PARTIAL_PAPER";
// submit question paper constants
export const SAVE_PAPER_LOADING = "SAVE_PAPER_LOADING";
export const SAVE_PAPER_SUCCESS = "SAVE_PAPER_SUCCESS";
export const CATCH_ERROR_SAVE_PAPER = "CATCH_ERROR_SAVE_PAPER";
export const CLEAR_ERROR_SAVE_PAPER = "CLEAR_ERROR_SAVE_PAPER";

// get single test paper
export const GET_SINGLE_PAPER_LOADING = "GET_SINGLE_PAPER_LOADING";
export const GET_SINGLE_PAPER_SUCCESS = "GET_SINGLE_PAPER_SUCCESS";
export const CATCH_ERROR_SINGLE_PAPER = "CATCH_ERROR_SINGLE_PAPER";
export const CLEAR_ERROR_SINGLE_PAPER = "CLEAR_ERROR_SINGLE_PAPER";

// get marking schema
export const GET_MARKING_SCHEMA_LOADING = "GET_MARKING_SCHEMA_LOADING";
export const GET_MARKING_SCHEMA_SUCCESS = "GET_MARKING_SCHEMA_SUCCESS";
export const CATCH_ERROR_MARKING_SCHEMA = "CATCH_ERROR_MARKING_SCHEMA";
export const CLEAR_ERROR_MARKING_SCHEMA = "CLEAR_ERROR_MARKING_SCHEMA";
