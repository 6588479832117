import * as actionType from "../actions/actions";

const INITIAL_STATE = {
  isLoading: false,
  data: {},
  error: null,
};
// eslint-disable-next-line import/prefer-default-export
export const getSingleTestReducer = (testPaper = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.GET_SINGLE_PAPER_LOADING:
      return {
        ...testPaper,
        isLoading: true,
      };
    case actionType.GET_SINGLE_PAPER_SUCCESS:
      return {
        ...testPaper,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_SINGLE_PAPER:
      return {
        ...testPaper,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_SINGLE_PAPER:
      return INITIAL_STATE;

    default:
      return testPaper;
  }
};
