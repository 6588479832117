import { useSelector, useDispatch } from "react-redux";
// mui
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
// actions
import { resetBackdropProcess, resetAlertInfo } from "redux/app/app.slice";
// selectors
import {
  selectAlertInfo,
  selectIsProcessing,
  selectProcessStatus,
  selectProcessType,
} from "redux/app/app.selectors";
// components
import Feedback from "components/Feedback";
// hooks
import useErrorAlert from "hooks/useErrorAlert";

const Loading = () => {
  const dispatch = useDispatch();
  const isProcessing = useSelector(selectIsProcessing);
  const processStatus = useSelector(selectProcessStatus);
  const processType = useSelector(selectProcessType);
  const alertInfo = useSelector(selectAlertInfo);

  const [mess, result, open, setOpen] = useErrorAlert(
    processStatus,
    resetBackdropProcess
  );

  const { alertOpen, msg } = alertInfo;

  const handleAlertClose = () => {
    setOpen(false);
    dispatch(resetBackdropProcess());
  };

  const handleAppAlertClose = () => {
    dispatch(resetAlertInfo());
  };
  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: 1301 }}
        open={isProcessing && processType !== "noBackdrop"}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {mess && result ? (
        <Feedback
          msg={mess}
          open={open}
          handleClose={handleAlertClose}
          type={result}
        />
      ) : null}

      <Feedback
        msg={msg}
        open={alertOpen}
        handleClose={handleAppAlertClose}
        type="info"
        aletStyles={{
          backgroundColor: "#808080	",
          color: "#ffff",
        }}
        position={{ x: "top", y: "center" }}
      />
    </>
  );
};

export default Loading;
