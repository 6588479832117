function isCurrentDate(receiptDate, serverDate) {
  const date = new Date(receiptDate);
  const today = new Date(serverDate);

  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
}
export default isCurrentDate;
