import styled from "@emotion/styled";
import LockResetIcon from "@mui/icons-material/LockReset";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
// myComponents
import SoftTypography from "myComponents/SoftTypography";
import SoftBox from "myComponents/SoftBox";

const Container = styled("div", {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active, theme }) => ({
  display: "flex",
  alignItems: "center",
  borderRadius: "20px",
  width: "fit-content",
  paddingRight: ".5rem",
  paddingLeft: ".5rem",
  height: "1.3rem",
  border: `1px solid "#DFE4E7"`,
  color: `${active ? theme.palette.primary.main : "#ED3B64"}`,
  backgroundColor: `${active ? "#DEF8E9" : "#F7D9D0"}`,
  opacity: `${active ? 1 : 0.6}`,
  cursor: "pointer",
}));

const Dot = styled("div", {
  shouldForwardProp: (prop) => prop !== "active",
})(({ active, theme }) => ({
  height: "0.30rem",
  width: "0.30rem",
  borderRadius: "50%",
  backgroundColor: `${active ? theme.palette.primary.main : "#ED3B64"}`,
  marginRight: ".2rem",
}));

const ActiveStatus = ({
  active,
  handleClick,
  lock,
  admissionCancelled,
  isTcGenerated,
}) => {
  if (lock) {
    return (
      <SoftBox display="flex" alignItems="center">
        <Tooltip title="Status request is progress">
          <Icon sx={{ mr: 0.5 }} color="error">
            <LockResetIcon />
          </Icon>
        </Tooltip>

        <Container active={active} onClick={handleClick}>
          <Dot active={active} />
          <SoftTypography variant="caption">
            {active === true
              ? "active"
              : admissionCancelled || isTcGenerated
              ? isTcGenerated
                ? "Tc Issused"
                : "cancelled"
              : "inactive"}
          </SoftTypography>
        </Container>
      </SoftBox>
    );
  }
  return (
    <Container active={active} onClick={handleClick}>
      <Dot active={active} />
      <SoftTypography variant="caption">
        {active === true
          ? "active"
          : admissionCancelled || isTcGenerated
          ? isTcGenerated
            ? "Tc Issused"
            : "cancelled"
          : "inactive"}
      </SoftTypography>
    </Container>
  );
};

export default ActiveStatus;
