import { useEffect } from "react";
// myComponents
import SoftBox from "myComponents/SoftBox";

const ScreenContainer = ({ children, ...props }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <SoftBox
      p={2}
      position="relative"
      sx={{ minHeight: "calc(100vh - 48px)" }}
      {...props}
    >
      {children}
    </SoftBox>
  );
};

export default ScreenContainer;
