import { useEffect } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ErrorBoundary } from "react-error-boundary";
// mui
import { ThemeProvider } from "@mui/material";
// notistack
import { SnackbarProvider } from "notistack";
import CssBaseline from "@mui/material/CssBaseline";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import store from "store";
// theme
import theme from "assets/theme";
// myComponents
import SoftSnackBar from "myComponents/SoftSnackBar";
// routes
import { redirectUser } from "APIs/baseAxios";
import ErrorFallback from "views/ErrorFallback";
import { LicenseInfo } from "@mui/x-license-pro";
import AppRoutes from "./routes/Routes";
// redux store
import storeRedux, { persistor } from "./redux/store";

const App = () => {
  const user = store.get("user");
  useEffect(() => {
    let timeoutId;
    LicenseInfo.setLicenseKey(
      "1258635abc81da59f5d9ac4cf79f4811Tz04MTAwMyxFPTE3MzQ4NTE3MjkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
    );
    const resetSessionTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        // Check if the user is not already on the login page
        if (user && window.location.pathname !== "/sign-in") {
          redirectUser();
          console.log("User redirected to login page due to inactivity");
        }
      }, 20 * 60 * 1000); // tracks 20+ mins inactivity
    };

    const handleUserActivity = () => {
      resetSessionTimeout();
    };

    // Attach event listeners for user activity
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    // Initial setup
    resetSessionTimeout();

    // Cleanup event listeners on component unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <Provider store={storeRedux}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <PersistGate persistor={persistor}>
              <SnackbarProvider
                Components={{
                  success: SoftSnackBar,
                  error: SoftSnackBar,
                }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                maxSnack={5}
              >
                <AppRoutes />
              </SnackbarProvider>
            </PersistGate>
          </LocalizationProvider>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  );
};

export default App;
