import moment from "moment";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PrintIcon from "@mui/icons-material/Print";
// x-data-grid
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  // GridToolbarExport,
  GridToolbarQuickFilter,
  // useGridApiContext,
  GridPagination,
  GridToolbarExportContainer,
  useGridApiContext,
  // GridCsvExportOptions,
  GridPrintExportMenuItem,
} from "@mui/x-data-grid-pro";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
// hooks
// import useHighlightInput from "hooks/useHighlightInput";
// api
import { postOperation } from "APIs/organization/operations";

export const ItemsSection = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexGrow: 1,
  [theme.breakpoints.up("lg")]: {
    paddingRight: theme.spacing(5),
  },
  marginLeft: theme.spacing(1.25),
}));
export const ItemsEndSection = styled("div")(() => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",

  flexGrow: 1,
}));
export const fontStyles = {
  fontSize: "12px",
  fontweight: 600,
  alignContent: "flex-end",
  color: "#31456A",
};

// const rowsRef = useGridApiContext().current;
// const val = rowsRef.state?.filter?.filterModel?.quickFilterValues[0];
// useHighlightInput(val, rowsRef?.windowRef);

const exportAllRows = async (title, sensitiveInfo, exp, params) => {
  if (sensitiveInfo) {
    if (title === "students_list") {
      const res = await postOperation({
        operation: "DOWNLOAD_STUDENTS_LIST",
        status: "SUCCESS",
        action: "DOWNLOAD",
        params: JSON.stringify(params),
      });
      if (res?.opId) {
        exp();
      }
    }
  } else {
    exp();
  }
};

const GridCsvExportMenuItem = (props) => {
  const { title, sensitiveInfo, hideMenu, options, dataParams, ...other } =
    props;
  const apiRef = useGridApiContext();
  return (
    <MenuItem
      onClick={() => {
        exportAllRows(
          title,

          sensitiveInfo,
          () =>
            apiRef.current.exportDataAsCsv({
              fileName: `${title || "Downloaded file"}_${moment().format()}`,
            }),
          dataParams
        );
        hideMenu?.();
      }}
      {...other}
      sx={{ fontSize: "12px" }}
    >
      <ListItemIcon>
        <SystemUpdateAltIcon fontSize="small" />
      </ListItemIcon>
      <ListItemText>Export CSV</ListItemText>
    </MenuItem>
  );
};

const csvOptions = { delimiter: ";", fileName: "plans" };
const printOptions = { fileName: "doc", hideFooter: true, hideToolbar: true };
const CustomToolbar =
  ({
    Component,
    gridPagination,
    columnsForTable,
    title,
    sensitiveInfo,
    exportForTable,
    dataParams = {},
    disableSerchBox,
  }) =>
  () =>
    (
      <GridToolbarContainer sx={{ p: "0.5rem" }}>
        {disableSerchBox ? null : <GridToolbarQuickFilter />}
        <ItemsEndSection>
          <Component />
        </ItemsEndSection>

        {gridPagination ? <GridPagination /> : null}
        {columnsForTable ? (
          <GridToolbarColumnsButton
            sx={{
              ...fontStyles,
            }}
          />
        ) : null}

        {/* <GridToolbarExport
          startIcon={<SystemUpdateAltIcon />}
          sx={{
            ...fontStyles,
          }}
        /> */}
        {exportForTable ? (
          <GridToolbarExportContainer
            sx={{
              ...fontStyles,
            }}
          >
            <GridCsvExportMenuItem
              options={csvOptions}
              title={title}
              sensitiveInfo={sensitiveInfo}
              dataParams={dataParams}
            />

            <MenuItem sx={{ fontSize: "12px" }}>
              <ListItemIcon>
                <PrintIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <GridPrintExportMenuItem
                  sx={{ fontSize: "20px" }}
                  options={printOptions}
                />
              </ListItemText>
            </MenuItem>
          </GridToolbarExportContainer>
        ) : null}
      </GridToolbarContainer>
    );
export default CustomToolbar;
