import { all, call } from "redux-saga/effects";
// sagas
import appSaga from "./app/app.saga";
import zonesSaga from "./organization/zones/zones.saga";
import userManagementSaga from "./organization/userManagement/userManagement.saga";
import academicYearSaga from "./organization/academicYear/academicYear.saga";
import tempSaga from "./temp/temp.saga";
import studentSaga from "./admissions/student/student.saga";
import branchSaga from "./organization/branches/branches.saga";
import batchesSaga from "./organization/batches/batches.saga";
import attendanceSaga from "./academics/attendance/attendance.saga";
import feePlanSaga from "./finance/feePlan/feePlan.saga";
import txnSaga from "./finance/makePayment/makePayment.saga";
import settingsSaga from "./settings/settings.saga";
import sequenceSaga from "./admissions/sequence/sequence.saga";
import timeTableSaga from "./academics/timetable/timetable.saga";
import dashboardSaga from "./dashboard/Summary/dashboard.saga";
import attendanceTimigsSaga from "./academics/timings/timings.saga";
import deviceSage from "./academics/BmDevice/BmDevice.saga";
import followupsSaga from "./admissions/followups/followups.saga";
import hostelSaga from "./hostel/hostel.saga";
import examsSaga from "./exams/exams.saga";
import testCreationSagaWatcher from "./data_test_creation/actions/sagaTestCreation";
import attendanceSagaWatcher from "./data_test_creation/attendancedata/actions/sagaAttendance";

export default function* rootSaga() {
  yield all([
    // app
    call(tempSaga),
    call(appSaga),
    call(examsSaga),
    // organization
    call(zonesSaga),
    call(userManagementSaga),
    call(academicYearSaga),
    call(branchSaga),
    call(batchesSaga),
    call(studentSaga),
    // academics
    call(attendanceSaga),
    call(timeTableSaga),
    call(attendanceTimigsSaga),
    // finance
    call(feePlanSaga),
    call(txnSaga),
    // settings
    call(settingsSaga),
    call(sequenceSaga),
    call(dashboardSaga),
    call(deviceSage),
    call(followupsSaga),
    call(hostelSaga),
    testCreationSagaWatcher(),
    attendanceSagaWatcher(),
  ]);
}
