// import { useState } from "react";
// material ui
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
// myComponents
import { styled } from "@mui/material/styles";
import SoftBox from "myComponents/SoftBox";
import SoftTypography from "myComponents/SoftTypography";
// custom
import { RequiredLabel } from ".";

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-inputRoot": {
    maxHeight: 42, // set the same height as the input field
    overflowY: "auto",
  },
  "& .MuiAutocomplete-listbox": {
    maxHeight: 200, // set a maximum height for the listbox
    overflowY: "auto", // enable scrolling if the listbox exceeds the maximum height
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SoftAutoCompleteMultiSelect({
  values,
  selectedValues,
  setValues,
  label,
  disabled,
  loading,
  // haveSelectAll,
  required,
  noHeaderLabel,
  optionLabelPropName,
  optionValuePropName,
  textFieldProps,
  simpleOptions = false,
  limitTags = 1,
  helperText,
  nLabel = false,
}) {
  // const [open, setOpen] = useState(false);
  // // const classes = useStyles();
  // const handleOpen = (bool) => setOpen(bool);

  const addProps = !simpleOptions
    ? {
        getOptionLabel: (option) =>
          option?.[optionLabelPropName || "label"] || "",
        isOptionEqualToValue: (option, item) =>
          option?.[optionValuePropName || "_id"] ===
          item?.[optionValuePropName || "_id"],
      }
    : {};
  const options =
    values && values?.length > 0
      ? [
          !simpleOptions
            ? {
                [optionValuePropName || "_id"]: "iAmSelectAll",
                [optionLabelPropName || "label"]: "Select All",
              }
            : "Select All",
          ...values.filter((value) => value !== null && value !== undefined),
        ]
      : [];

  return (
    <SoftBox>
      {label && nLabel ? (
        <SoftBox ml={0.5} mb={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            {label}
          </SoftTypography>
        </SoftBox>
      ) : null}
      {label && !nLabel ? (
        <SoftBox ml={0.5} mb={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            {label ? (
              <SoftBox ml={0.5}>
                <RequiredLabel
                  label={label}
                  required={required}
                  noHeaderLabel={noHeaderLabel}
                />
              </SoftBox>
            ) : null}
          </SoftTypography>
        </SoftBox>
      ) : null}
      <StyledAutocomplete
        // open={open}
        multiple
        disableCloseOnSelect
        disabled={disabled}
        limitTags={limitTags}
        options={options}
        value={selectedValues || []}
        // freeSolo
        fullWidth
        loading={loading}
        {...addProps}
        onChange={(event, newValue) => {
          const lastItem = newValue[newValue.length - 1] || 0;
          if (
            simpleOptions
              ? lastItem === "Select All"
              : lastItem[optionValuePropName || "_id"] === "iAmSelectAll"
          ) {
            const vals =
              values?.length === selectedValues?.length ? [] : [...values];

            setValues(vals);
          } else setValues([...newValue]);
        }}
        // renderOption={(props, option, { selected }) => {
        //   if (option) {
        //     return (
        //       <li {...props}>
        //         <Checkbox
        //           icon={icon}
        //           checkedIcon={checkedIcon}
        //           style={{ marginRight: 8 }}
        //           checked={
        //             (simpleOptions
        //               ? option
        //               : option[optionLabelPropName || "label"]) === "Select All"
        //               ? values?.length === selectedValues?.length
        //               : selected
        //           }
        //         />
        //         {simpleOptions
        //           ? option
        //           : option[optionLabelPropName || "label"]}
        //       </li>
        //     );
        //   }
        //   return null;
        // }}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={
                option &&
                (simpleOptions
                  ? option
                  : option[optionLabelPropName || "label"]) === "Select All"
                  ? values?.length === selectedValues?.length
                  : selected
              }
            />
            {option &&
              (simpleOptions ? option : option[optionLabelPropName || "label"])}
          </li>
        )}
        renderInput={(params) => (
          <>
            <TextField
              required={values?.length === 0 ? required : false}
              placeholder={label}
              {...params}
              {...textFieldProps}
            />
            {helperText && (
              <SoftBox
                ml={0.5}
                mb={0.5}
                display="flex"
                jsutifyContent="center"
                alignItems="center"
              >
                <SoftTypography
                  component="label"
                  variant="caption"
                  // fontWeight="bold"
                >
                  {label ? (
                    <SoftBox ml={0.5} mt={0.5}>
                      &nbsp;
                      <SoftTypography
                        variant="caption"
                        // fontWeight="bold"
                        color="error"
                      >
                        {helperText}
                      </SoftTypography>
                    </SoftBox>
                  ) : null}
                </SoftTypography>
              </SoftBox>
            )}
          </>
        )}
      />
    </SoftBox>
  );
}
