import baseAxios from "APIs/baseAxios";
import lambda from "APIs/lambdaConfig";

const COMMON = "/academics/attendance";

export async function createAttendance(obj) {
  try {
    return (await baseAxios.post(COMMON, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function saveManualAttendance(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/manual`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchAttendance(obj) {
  const { id, ...rest } = obj;
  const path = id ? `${COMMON}/${id}` : COMMON;
  try {
    return (await baseAxios.get(path, { params: { ...rest } })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchAttendanceReport(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/report`, { params: { ...obj } }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchAttendanceSummary(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/summary`, { params: { ...obj } }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchDashBoardAttendanceSummary(obj) {
  try {
    return (
      await baseAxios.get(`${COMMON}/dashboard-summary`, { params: { ...obj } })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchSingleStudentReport(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/single`, { params: { ...obj } }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getDailyAttendance(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/daily`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getSectionStatus(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/sec_status`, { params: { ...obj } }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function updateAttendance(obj) {
  try {
    return (await baseAxios.patch(COMMON, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getAttSMSReports(body) {
  try {
    const params = {
      FunctionName: "AttendanceSMS-DeliveryReport-bxzeex5o7S77",
      Payload: JSON.stringify(body),
    };
    const res = await lambda.invoke(params).promise();
    return JSON.parse(res.Payload);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getSMSTasks() {
  try {
    return (await baseAxios.get(`${COMMON}/tasks`)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchPuncesReport(obj) {
  try {
    return (
      await baseAxios.get(`${COMMON}/punches-summary`, { params: { ...obj } })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchSinglePuncesReport(obj) {
  try {
    return (
      await baseAxios.get(`${COMMON}/zkt-student`, { params: { ...obj } })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchSingleStudentManAttReport(obj) {
  try {
    return (
      await baseAxios.get(`${COMMON}/man-student`, { params: { ...obj } })
    ).data;
  } catch (error) {
    return Promise.reject(error);
  }
}
export async function fetchCumulativeAttReport(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/man-reports`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchAttendanceSmsTasks(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/day-tasks`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function sendBranchAttendanceSMS(body) {
  try {
    const params = {
      FunctionName: "AttendanceOp-MsgServices-Tjx0NB6Qo0cG",
      Payload: JSON.stringify(body),
    };
    const res = await lambda.invoke(params).promise();
    const result = JSON.parse(res.Payload);
    console.log(result);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function saveScheduledAttendance(obj) {
  try {
    return (await baseAxios.post(`/academics/stu-sch/save-att`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchScheduleAttendance(obj) {
  try {
    return (await baseAxios.post(`/academics/stu-sch/get-att`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchScheduleSlots(obj) {
  try {
    return (await baseAxios.post(`/academics/stu-sch/get-slots`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchBranchAttendanceReport(obj) {
  try {
    return (await baseAxios.post(`/academics/stu-sch/report-branch`, obj)).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchMonthlyAttReportOfBranch(obj) {
  try {
    return (await baseAxios.post(`/academics/stu-sch/report-monthly`, obj))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}

// attendanceRoutes.get(
//   "/zone-summary",
//   getAccessibleParams,
//   summaryMatch,
//   attSummaryController.getZoneWiseAttendanceSummary
// );
// attendanceRoutes.get(
//   "/branch-summary",
//   getAccessibleParams,
//   summaryMatch,
//   attSummaryController.getBranchWiseAttendanceSummary
// );
// attendanceRoutes.get(
//   "/all-summary",
//   getAccessibleParams,
//   summaryMatch,
//   attSummaryController.getAttendanceSummary
// );

// TODO: Add the above routes
export async function getZoneWiseAttendanceSummary(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/zone-summary`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getBranchWiseAttendanceSummary(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/branch-summary`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchAttendanceSummaryNew(obj) {
  try {
    return (await baseAxios.post(`${COMMON}/all-summary`, { ...obj })).data;
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function fetchBranchPunches(obj) {
  try {
    return (await baseAxios.get(`${COMMON}/branch-punches`, { params: obj }))
      .data;
  } catch (error) {
    return Promise.reject(error);
  }
}
