import { createSelector } from "@reduxjs/toolkit";

const selectBranchesState = (state) => state.branches;

export const selectBranchesList = createSelector(
  [selectBranchesState],
  (state) => state.branchesList
);

export const selectBranch = createSelector(
  [selectBranchesState],
  (state) => state.branch
);

export const selectQuery = createSelector(
  [selectBranchesState],
  (state) => state.query
);

export const selectBranchId = createSelector(
  [selectBranchesState],
  (state) => state.branchId
);

export const selectEditPageIndex = createSelector(
  [selectBranchesState],
  (state) => state.editPageIndex
);

export const selectBranchesMap = createSelector([selectBranchesList], (list) =>
  list.reduce((a, c) => {
    a[c.branchCode] = c;
    return a;
  }, {})
);

export const selectBranchMapById = createSelector(
  [selectBranchesList],
  (list) =>
    list.reduce((a, c) => {
      a[c._id] = c;
      return a;
    }, {})
);

export const selectBranchesMapByBranchCode = createSelector(
  [selectBranchesList],
  (list) =>
    list.reduce((a, c) => {
      a[c.branchCode] = c;
      return a;
    }, {})
);

export const selectBranchFromMapOfBranchCode = (code) =>
  createSelector([selectBranchesMapByBranchCode], (map) => map[code]);

export const selectBranchFromMapOFBranchId = (id) =>
  createSelector([selectBranchesMap], (map) => map[id]);

export const selectGroupedBranchesBasedOnZones = createSelector(
  [selectBranchesList],
  (list) =>
    list.reduce((a, c) => {
      if (!a[c.zone]) {
        a[c.zone] = [];
      }
      a[c.zone].push(c.branchCode);
      return a;
    }, {})
);
