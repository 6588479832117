/* eslint-disable no-case-declarations */
import { capitalize } from "lodash";
import store from "store";
import * as actionType from "../actions/actions";

const INITIAL_STATE = {
  data: [],
};

// eslint-disable-next-line import/prefer-default-export
export const selectedQuestionsReducer = (questions = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionType.SAVE_SELECTED_QUESTIONS_TO_STORE:
      const metaData = store.get("qMeta") || {};
      let ifOutOfRange = true;
      const countLimit = store.get("questionData") || {};
      // max limit form schema
      const maxCount =
        metaData && action.payload.subject
          ? metaData[capitalize(action.payload.subject)]
          : {};
      // selected question count form store
      const limit = countLimit[capitalize(action.payload.subject)]
        ? countLimit[capitalize(action.payload.subject)][
            action.payload.questionType
          ].length
        : 0;
      if (limit < maxCount[action.payload.questionType]) ifOutOfRange = false;
      if (ifOutOfRange) {
        return { ...questions, data: [...questions.data] };
      }
      const map = new Map();
      let selectedQuestion = questions.data;
      for (const obj of selectedQuestion) {
        map.set(JSON.stringify(obj), true);
      }
      if (!map.has(JSON.stringify(action.payload))) {
        selectedQuestion = [...selectedQuestion, action.payload];
      }
      return { ...questions, data: [...selectedQuestion] };

    case actionType.REMOVE_SELECTED_QUESTIONS_FROM_STORE:
      return {
        ...questions,
        data: [
          ...questions.data.filter((ques) => ques.qId !== action.payload.qId),
        ],
      };
    case actionType.REMOVE_ALL_SELECTED_QUESTIONS_FROM_STORE:
      return {
        ...questions,
        data: [],
      };
    case actionType.ADD_PARTIAL_PAPER:
      return {
        ...questions,
        data: action.payload,
      };
    default:
      return questions;
  }
};
