// mui
import BusinessIcon from "@mui/icons-material/Business";
// components
import Container from "layout/simpleContainer";
import features from "config/features";
import flatten from "lodash/flatten";
// Helpers
import lazy from "helpers/LazyImport";
// view
const BuildingsMgnt = lazy(() =>
  import("../../views/InfraStructure/BuildingsMgnt")
);
const SeatingAllotment = lazy(() =>
  import("../../views/InfraStructure/SeatingAllotment")
);
const BuildingsAssignment = lazy(() =>
  import("../../views/InfraStructure/SeatingAllotment/BuildingsAssignment")
);
const FilterData = lazy(() =>
  import("../../views/InfraStructure/SeatingAllotment/FilterData")
);

// infra structure primary module
export default {
  component: <Container />,
  title: "Infra Structure",
  basePath: "app",
  path: "infra-structure",
  icon: <BusinessIcon />,
  features: [...flatten(Object.values(features.infra_structure))],
  children: [
    {
      component: <BuildingsMgnt />,
      title: "Buildings Management",
      basePath: "infra-structure",
      path: "buildings",
      features: features.infra_structure?.building_management,
    },
    {
      component: <Container />,
      title: "Seating Allotment",
      basePath: "infra-structure",
      path: "seating-allotment",
      index: <SeatingAllotment />,
      features: features.infra_structure?.seating_allotment,
      children: [
        {
          component: <BuildingsAssignment />,
          title: "Buildings Assignment",
          basePath: "seating-allotment",
          path: "config",
          features: features.infra_structure?.seating_allotment,
        },
        {
          component: <FilterData />,
          title: "Report",
          basePath: "seating-allotment",
          path: "reports",
          features: features.infra_structure?.seating_allotment,
        },
      ],
    },
  ],
};
