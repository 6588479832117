import * as actionType from "../actions/actions";

// class wise subjects , topics and subtopics details
const INITIAL_CLASS_DETAILS = {
  isLoading: false,
  data: [],
  error: null,
};
// eslint-disable-next-line import/prefer-default-export
export const classWiseDetailsReducer = (
  classDetails = INITIAL_CLASS_DETAILS,
  action
) => {
  switch (action.type) {
    case actionType.FETCH_CLASS_DETAILS_LOADING:
      return {
        ...classDetails,
        isLoading: true,
      };
    case actionType.FETCH_CLASS_DETAILS_SUCCESS:
      return {
        ...classDetails,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_FETCH_CLASS:
      return {
        ...classDetails,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_FETCH_CLASS:
      return INITIAL_CLASS_DETAILS;
    default:
      return classDetails;
  }
};
