import * as myFeauters from "config/features";

export const isNotEmptyArray = (arr) => Array.isArray(arr) && arr.length;

const expensesFeatures = [
  "credit_cash",
  "add_to_ledger",
  "expenses_report",
  "opening_and_closing_expenses_report",
  "branches_report",
  "user_read",
  "user_write",
  ...myFeauters.default.organization.branches,
  ...myFeauters.default.organization.role_management,
];

const expensesCond = window.location.hostname.includes("expenses");

export const getAllowedRoutes = (
  routes,
  assignedRoles,
  privileges = {},
  attendanceConf = {},
  instituteId,
  addedPrivileges = [],
  removedPrivileges = []
) => {
  const filteredRoutes = routes?.filter(({ features = [], path }) => {
    const fe = features?.filter((feature) => {
      if (expensesCond) {
        return expensesFeatures.includes(feature);
      }
      return true;
    });

    if (path === "profile") return true;

    if (
      path === "zoom-live" &&
      ![
        "5e7370083d8520a40c4ceaaa",
        "5ee85fc60f25a93146ea0bf9",
        "61e850055876f313e30ea1de",
        "5dc1388b22bdc209de6a4d80",
        "5e9098aaa218252d49be5a3b",
        "65772522ee64cfa8dc9851f2",
        "5ee85fc60f25a93146ea0s01n",
      ].includes(instituteId)
    )
      return false;
    if (path === "daily-attendance" && attendanceConf?.type === "auto")
      return false;
    if (path === "att-reports" && attendanceConf?.type === "auto") return false;
    if (path === "punches-report" && attendanceConf?.type !== "auto")
      return false;
    if (path === "single_punches_report" && attendanceConf?.type !== "auto")
      return false;
    if (
      path === "attendance-push-notifications" &&
      !["6422b982a32e9b7c5498b129"].includes(instituteId)
    )
      return false;

    if (assignedRoles?.includes("SUPER_ADMIN") && expensesCond)
      return fe?.length;

    if (assignedRoles?.includes("SUPER_ADMIN")) return true;

    if (!(fe && fe?.length)) return false;

    const reNewedPrivileges = {
      ...privileges,
      ...addedPrivileges.reduce((a, c) => {
        a[c] = [...assignedRoles];
        return a;
      }, {}),
      ...removedPrivileges.reduce((a, c) => {
        a[c] = [];
        return a;
      }, {}),
    };

    const test = assignedRoles?.filter((role) =>
      fe.some((item) => (reNewedPrivileges || {})?.[item]?.includes(role))
    ).length;

    return test;
  });

  return filteredRoutes;
};
