import { lazy } from "react";
import GiteRoundedIcon from "@mui/icons-material/GiteRounded";
// import features from "config/features";
import BodyWrapper from "../../layout/bodyWrapper";
import Container from "../../layout/simpleContainer";
import roles from "../roles";

export const BuildingManagementSettings = lazy(() =>
  import("../../views/BuildingManagement/BuildingManagementSettings")
);

export default {
  component: <BodyWrapper />,
  title: "Building Management",
  basePath: "app",
  path: "building",
  icon: <GiteRoundedIcon />,
  features: [],
  children: [
    {
      // component: <Container />,
      title: "Building Creation",
      basePath: "building",
      path: "building-settings",
      component: <BuildingManagementSettings />,
      features: [],
    },
  ],
};
