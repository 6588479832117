// platformDetector.js

const PlatformDetector = {
  // Check if running on iOS
  isIOS() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  },

  // Check if running on Android
  isAndroid() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /android/.test(userAgent);
  },

  // Check if running in a mobile browser
  isMobile() {
    return this.isIOS() || this.isAndroid();
  },

  // Check if running in a web browser
  isWeb() {
    // If it's not mobile and we're in a browser environment, it's web
    return !this.isMobile() && typeof window !== "undefined";
  },

  // Get the current platform as a string
  getCurrentPlatform() {
    if (this.isIOS()) return "ios";
    if (this.isAndroid()) return "android";
    return "web";
  },
};

// Export for use in other files
export default PlatformDetector;
