import * as actionType from "../actions/actions";
// get list of questions from question bank
const INITIAL_QB_DETAILS = {
  isLoading: false,
  data: [],
  error: null,
};
// eslint-disable-next-line import/prefer-default-export
export const getQBReducer = (questionBank = INITIAL_QB_DETAILS, action) => {
  switch (action.type) {
    case actionType.FETCH_QUESTIONBANK_LOADING:
      return {
        ...questionBank,
        isLoading: true,
      };
    case actionType.FETCH_QUESTIONBANK_SUCCESS:
      return {
        ...questionBank,
        isLoading: false,
        data: action.payload,
      };
    case actionType.CATCH_ERROR_FETCH_QB:
      return {
        ...questionBank,
        isLoading: false,
        error: action.payload,
      };
    case actionType.CLEAR_ERROR_FETCH_QB:
      return {
        ...questionBank,
        isLoading: false,
        error: null,
      };

    default:
      return questionBank;
  }
};
