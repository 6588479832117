import { useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";

const selectZones = (state) => state.zones;

export const selectZonesList = createSelector(
  [selectZones],
  (zones) => zones.zonesList
);

export const selectQuery = createSelector(
  [selectZones],
  (zones) => zones.query
);

export const selectZoneByProp = (value, prop) =>
  createSelector([selectZonesList], (list) =>
    list.find((ele) => ele[prop || "_id"] === (value || ""))
  );

export const selectZone = createSelector([selectZones], (zones) => zones.zone);

export const selectZonesMap = createSelector([selectZonesList], (list) =>
  list.reduce((acc, ele) => {
    acc[ele.zoneCode] = ele;
    return acc;
  }, {})
);

export const selectZonesMapById = createSelector([selectZonesList], (list) =>
  list.reduce((acc, ele) => {
    acc[ele._id] = ele;
    return acc;
  }, {})
);

const zoneSelectors = () => {
  // when called api returns true, after api call success or failure returns false
  const isFetchingZones = useSelector((state) => {
    const zonesState = state.zones;
    const bool = zonesState.isProcessing;
    return bool;
  });
  // zonesMap { year1: list1, year2: list2, year3: list3 }
  const zonesList = useSelector((state) => {
    const zonesState = state.zones;
    const map = zonesState.zonesList;
    return map;
  });
  // error could be fetch error or update error
  const error = useSelector((state) => {
    const zonesState = state.zones;
    const err = zonesState.error;
    return err;
  });
  // year selected which is independent of year selected in header(topbar)
  const year = useSelector((state) => {
    const ye = state.zones.year;
    return ye;
  });

  const result = useSelector((state) => {
    const zoneState = state.zones;
    const res = zoneState.status;
    return res;
  });

  return { isFetchingZones, zonesList, error, year, result };
};

export default zoneSelectors;
