import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer, createTransform } from "redux-persist";
import storage from "redux-persist/lib/storage";

// app reducers
import tempReducer from "./temp/temp.slice";
import appReducer from "./app/app.slice";
import filtersReducer from "./filters/filters.slice";
import staticeReducer from "./static/static.slice";
import notificationsReducer from "./notifcations/notifications.slice";
import examsReducer from "./exams/exams.slice";
// organization reducers
import zoneReducer from "./organization/zones/zones.slice";
import branchesReducer from "./organization/branches/branches.slice";
import userManagementReducer from "./organization/userManagement/userManagement.slice";
import academicYearsReducer from "./organization/academicYear/academicYear.slice";
import batchesReducer from "./organization/batches/batches.slice";
// admissions reducers
import studentReducer from "./admissions/student/student.slice";
// acadmeics reducers
import attendanceReducer from "./academics/attendance/attendance.slice";
import timetableReducer from "./academics/timetable/timetable.slice";
import timingsReducer from "./academics/timings/timings.slice";
import deviceReducer from "./academics/BmDevice/BmDevice.slice";
// finance reducers
import feePlanReducer from "./finance/feePlan/feePlan.slice";
import makePaymentReducer from "./finance/makePayment/makePayment.slice";
// settings reduces
import settingsReducer from "./settings/settings.slice";
import sequenceReducer from "./admissions/sequence/sequence.slice";
// dashboard reducers
import dashboardReducer from "./dashboard/Summary/dashboard.slice";
import followupsReducer from "./admissions/followups/followups.slice";
import hostelReducer from "./hostel/hostel.slice";
// summaries reducer
import summariesReducer from "./summaries/summaries.slice";
import { getQBReducer } from "./data_test_creation/reducers/QuestionBankReducer";
import { saveTestCreationQueryReducer } from "./data_test_creation/reducers/SaveTestCreationReducer";
import { classWiseDetailsReducer } from "./data_test_creation/reducers/ClassDetailsReducer";
import { selectedQuestionsReducer } from "./data_test_creation/reducers/SelectedQuestionsReducer";
import { getSingleTestReducer } from "./data_test_creation/reducers/GetSingleTestReducer";
import { createTestPaperReducer } from "./data_test_creation/reducers/CreateTestPaperReducer";
import { markingSchemaReducer } from "./data_test_creation/reducers/MarkingSchemaReducer";
import { getVideosUrlReducer } from "./data_test_creation/attendancedata/reducers/GetVideosUrlReducer";
import { getCumulativeAttendanceReducer } from "./data_test_creation/attendancedata/reducers/GetCumulativeAttendanceReducer";

const rootReducer = combineReducers({
  app: appReducer,
  temp: tempReducer,
  filters: filtersReducer,
  static: staticeReducer,
  notifications: notificationsReducer,
  exams: examsReducer,
  // organization
  zones: zoneReducer,
  branches: branchesReducer,
  userManagement: userManagementReducer,
  academicYearsManagement: academicYearsReducer,
  batches: batchesReducer,
  // admissions
  student: studentReducer,
  // academics
  attendance: attendanceReducer,
  timetable: timetableReducer,
  // finance
  feePlan: feePlanReducer,
  makePayment: makePaymentReducer,
  // settings
  settings: settingsReducer,
  sequence: sequenceReducer,
  dashboard: dashboardReducer,
  attendance_timings: timingsReducer,
  device: deviceReducer,
  followup: followupsReducer,
  hostel: hostelReducer,
  SUMMARIES: summariesReducer,
  // test paper creation
  questionBank: getQBReducer,
  queryQB: saveTestCreationQueryReducer,
  classDetails: classWiseDetailsReducer,
  selectedQuestions: selectedQuestionsReducer,
  singleTestDetails: getSingleTestReducer,
  createdPaper: createTestPaperReducer,
  markingSchema: markingSchemaReducer,
  // cumulative attendance;
  allVideosUrl: getVideosUrlReducer,
  cumulativeAttendance: getCumulativeAttendanceReducer,
});

const appTransform = createTransform(
  (inboundState, key) => {
    if (key === "app") {
      const { processStatus, ...other } = inboundState;
      return other;
    }
    return inboundState;
  },
  (outboundState, key) => {
    if (key === "app") {
      return { processStatus: {}, ...outboundState };
    }
    return outboundState;
  }
);

const persistConfig = {
  key: "root",
  storage,
  whitelist: [
    "static",
    "zones",
    "batches",
    "branches",
    "academicYearsManagement",
    "settings",
    "dashboard",
    "app",
  ],
  transforms: [appTransform],
};

export default persistReducer(persistConfig, rootReducer);
