import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
// mui
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import AppBar from "@mui/material/AppBar";
// selectors
import { selectAcademicYear } from "redux/app/app.selectors";
import { selectAcademicYears } from "redux/organization/academicYear/academicYear.selectors";
// actions
import { setAcademicYear } from "redux/app/app.slice";
// myComponents
import SoftTypography from "myComponents/SoftTypography";
import SoftBox from "myComponents/SoftBox";
import SoftButton from "myComponents/SoftButton";
// styles
import menuItem from "layout/primeLayout/Topbar/AccountMenu/styles";
// assets
import KeyboardArrowDown from "assets/svgs/ArrowDown.svg";
import ArrowBack from "assets/svgs/ArrowBack.svg";
// utils
import pxToRem from "assets/theme/functions/pxToRem";

const MobileHeader = ({ title, haveAySelect = true, handleBack }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const yearsList = useSelector(selectAcademicYears);
  const ay = useSelector(selectAcademicYear);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <AppBar color="mobileBackGround">
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          pt={0.8}
          pb={0.8}
          pl={2}
          pr={2}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => {
              if (handleBack) {
                handleBack();
              } else {
                navigate(-1);
              }
            }}
          >
            <img src={ArrowBack} alt="Back" />
          </IconButton>
          <SoftTypography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
            fontWeight="bold"
          >
            {title}
          </SoftTypography>
          {haveAySelect && (
            <>
              <SoftButton
                variant="depth"
                onClick={handleClick}
                radius="sm"
                fontSize={14}
                fontWeight={500}
                size="small"
              >
                {ay.name?.replaceAll(" ", "")}
                &nbsp;&nbsp;
                <img src={KeyboardArrowDown} alt="Icon description" />
              </SoftButton>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                color="dark"
              >
                {yearsList?.length > 0 &&
                  yearsList.map((year, index) => (
                    <MenuItem
                      key={`ay-${index}`}
                      onClick={() => {
                        dispatch(setAcademicYear(yearsList[index]));
                        handleClose();
                      }}
                      sx={(theme) => menuItem(theme)}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        sx={{ ml: 1 }}
                      >
                        {year.name}
                      </SoftTypography>
                    </MenuItem>
                  ))}
              </Menu>
            </>
          )}
        </SoftBox>
      </AppBar>
      <SoftBox height={pxToRem(48)} width="100%" />
    </>
  );
};

export default MobileHeader;
